<template>
    <div
        class="input-container d-flex flex-column w-100 bg-white justify-content-center"
    >
        <div class="d-flex align-items-center position-relative w-100">
            <div
                class="search-bar-container d-flex align-items-center px-lg-0 w-100 m-0"
            >
                <div class="d-flex align-items-center" style="height: 44px;">
                    <SearchSvg class="mx-3 search-icon" />
                    <p
                        v-if="type === 'mobile' && inputWords.length > 0"
                        :class="inputWords.length > 0 ? '' : 'text-b5b3ab'"
                        class="text-16 detached-placeholder-btn"
                        style="color: #212529;"
                    >
                        {{ inputWords }}
                    </p>
                    <AnimatedText
                        v-else-if="animatedPlaceholders?.length > 0"
                        :input-words="inputWords"
                        :is-show-immediate-placeholder="
                            isShowImmediatePlaceholder
                        "
                        :is-panel-opened="isPanelOpened"
                        :class="
                            isLanding
                                ? 'landing-animated-text'
                                : 'search-animated-text'
                        "
                        @updateIndex="updateAnimateIndex"
                    />
                    <span
                        v-else-if="inputWords.length <= 0"
                        class="text-14 text-797979 col"
                        style="position: absolute; left: 33px; z-index: 2; pointer-events: none;"
                    >
                        {{ searchBarPlaceholder }}
                    </span>
                </div>
                <div
                    :id="type + '-autocomplete'"
                    class="col-10 col-lg-12 pl-5"
                    style="z-index: 1;"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import { autocomplete } from '@algolia/autocomplete-js'
import dateServices from '@/services/dateServices'
import SearchSvg from '@/components/iconSvg/SearchSvg'
import {
    AUTOCOMPLETE_RESET_CLICK,
    getSpaceTypeId,
    debouncePromise,
    getAlgoliaLang,
    getSuggestionIcon
} from '@/services/algoliaApi'
import {
    computeSuggestionIndex,
    computeSuggestionFilter,
    executeAlgoliaSearch
} from '@/lib-flow-main-search/src/search'
import NextApi from '@/services/nextApi'
import AnimatedText from '@/components/ui/AnimatedText'
Vue.use(VueScrollTo)

export default {
    name: 'LocationSearchPanel',
    components: { SearchSvg, AnimatedText },
    props: {
        mapLoading: {
            type: Boolean,
            default: false
        },
        inputWords: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        spaceTypeName: {
            type: String,
            default: 'rest',
            required: true
        },
        querySource: {
            type: String,
            default: '',
            required: false
        },
        isShowImmediatePlaceholder: {
            type: Boolean,
            default: false,
            required: false
        },
        isShowPlaceholderSuggestion: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data() {
        return {
            selected: {
                date: dateServices.getToday()
            },
            setIsOpenHandler: undefined,
            updateHandler: undefined,
            animIndex: 0,
            isPanelOpened: false
        }
    },
    computed: {
        dateList() {
            return this.$store.getters['api/availableDateList']
        },
        spaceTypeInfo() {
            return this.$store.getters['api/spaceTypeInfo']
        },
        isSpaceTypeLoaded() {
            return this.spaceTypeInfo.length !== 0
        },
        getToday() {
            return dateServices.getToday()
        },
        getTomorrow() {
            return dateServices.getTomorrow()
        },
        algoliaLang() {
            return getAlgoliaLang(
                this.$route.params.region,
                this.$route.params.locale
            )
        },
        searchBarPlaceholder() {
            return (
                this.$store.getters['api/searchBarPlaceholder'] ??
                this.$t('search_bar.current_location')
            )
        },
        animatedPlaceholders() {
            return this.$store.getters['api/animatedPlaceholders']
        },
        isLanding() {
            return this.$route.name === 'region-locale'
        },
        prefGroup() {
            return this.$store.getters['general/prefGroup']
        },
        searchConfig() {
            return this.$store.getters['api/searchConfig']
        }
    },
    watch: {
        prefGroup: {
            immediate: true,
            async handler(newVal) {
                if (newVal && this.paramsRegion === 'my')
                    this.refreshAutoComplete()
            }
        }
    },
    mounted() {
        if (this.type === 'desktop') {
            window.addEventListener('scroll', this.handleScrollMethod)
        }
        const popularSearchTitle = this.$t('search_bar.popular_search')
        const debounced = debouncePromise(
            (items) => Promise.resolve(items),
            300
        )
        /** variables and methods require 'this' scope */
        const _lang = this.paramsLang
        const _region = this.paramsRegion
        const _algoliaLang = this.algoliaLang
        const _group = this.prefGroup
        const _spaceTypeName = this.spaceTypeName
        const _deviceType = this.type
        const _currentLocationTitle = this.$t('search_bar.current_location')
        const _searchNearbyButtonTitle = this.$t('search_bar.search_nearby')
        const _detachedCancelTitle = this.$t('cancel')
        const _isShowPlaceholderSuggestion = this.isShowPlaceholderSuggestion
        const _isShowAnimSuggestion = _region !== 'my'
        const _searchConfig = this.searchConfig
        const _closeAutocompletePanel = () => {
            this.closeAutocompletePanel()
        }
        const _onSuggestionSelected = (item, isQuerySuggestion) => {
            this.setSuggestionSelected(item, isQuerySuggestion)
        }
        const _setInput = (item) => {
            this.setInput(item)
        }
        const _onSearchNearbyClicked = () => {
            this.onSearchNearbyClicked()
        }
        const _onSubmit = () => {
            this.onAutoCompleteSubmit()
        }
        const _currAnimItems = () => {
            return this.querySource === 'es'
                ? []
                : [this.animatedPlaceholders[this.animIndex]]
        }
        const _setIsPanelOpened = (isOpen) => {
            this.isPanelOpened = isOpen
        }
        const { setIsOpen, update } = autocomplete({
            container: '#' + this.type + '-autocomplete',
            openOnFocus: true,
            detachedMediaQuery: '(max-width: 991px)',
            translations: {
                detachedCancelButtonText: _detachedCancelTitle
            },
            classNames: {
                form: 'search-form',
                input: 'search-input',
                submitButton: 'search-icon-btn',
                inputWrapper: 'search-input-wrapper',
                loadingIndicator: 'search-loading',
                detachedSearchButton: 'detached-search-btn',
                detachedSearchButtonIcon: 'detached-search-btn-icon',
                detachedCancelButton: 'detached-cancel-btn',
                detachedSearchButtonPlaceholder: 'detached-button-placeholder'
            },
            onStateChange({ state }) {
                _setIsPanelOpened(state.isOpen)
                if (state.query) {
                    _setInput(state.query)
                } else {
                    _setInput(AUTOCOMPLETE_RESET_CLICK)
                }
            },
            onSubmit() {
                _onSubmit()
            },
            onReset() {
                _setInput(AUTOCOMPLETE_RESET_CLICK)
            },
            initialState: {
                query: this.inputWords
            },
            getSources: () => {
                return debounced([
                    {
                        sourceId: 'animPlaceholder',
                        getItems() {
                            return _isShowPlaceholderSuggestion
                                ? _currAnimItems()
                                : []
                        },
                        onSelect({ state, item }) {
                            state.query = item.query
                            _closeAutocompletePanel()
                            _onSuggestionSelected(item, true)
                        },
                        templates: {
                            item({ item, html }) {
                                return html`
                                    <div class="d-flex">
                                        <div
                                            class="chip-btn d-flex align-items-center justify-content-center button-active"
                                        >
                                            <span
                                                class="text-404040 text-s-bold"
                                                >${item.query}</span
                                            >
                                        </div>
                                    </div>
                                `
                            }
                        }
                    },
                    {
                        sourceId: 'querySuggestion',
                        async getItems({query}) {
                            const searchConfigRes = await NextApi.api.getSearchConfig(_spaceTypeName, {
                                platform: 'web',
                                region: _region,
                                lang: _lang,
                            })
                            const searchConfig = searchConfigRes.data.Data
                            const suggestions = await executeAlgoliaSearch(
                                computeSuggestionIndex(
                                    _region,
                                    getSpaceTypeId(_spaceTypeName),
                                    process.env.TYPESENSE_ENV
                                ),
                                {
                                    q: query === '' ? '*' : query,
                                    filter_by: computeSuggestionFilter({
                                        query,
                                        algoliaLang: _algoliaLang,
                                        group: _group,
                                        region: _region
                                    }),
                                    per_page: 5,
                                    queryParameters: {
                                        region: _region,
                                        query_suggestion: true
                                    },
                                    query_by: searchConfig.search.suggestions.query_by,
                                    sort_by: searchConfig.search.suggestions.sort_by_order.join(','),
                                },
                                true
                            )
                            return suggestions.hits
                        },
                        onSelect({ state, item }) {
                            state.query = item.query
                            _closeAutocompletePanel()
                            _onSuggestionSelected(item, true)
                        },
                        templates: {
                            item({ item, html }) {
                                return html`
                                    <div class="d-flex align-items-center">
                                        <img
                                            src="${getSuggestionIcon(
                                                _spaceTypeName,
                                                item.category
                                            )}"
                                            height="20"
                                            width="20"
                                            alt="category"
                                            style="display: inline-block;"
                                        />
                                        <div class="ml-4">
                                            <p class="text-15">
                                                ${item.query}
                                            </p>
                                            <p class="text-12">
                                                ${// eslint-disable-next-line standard/computed-property-even-spacing
                                                item.category_text?.[
                                                    _algoliaLang
                                                ] ?? ''}
                                            </p>
                                        </div>
                                    </div>
                                `
                            }
                        }
                    },
                    {
                        sourceId: 'popularSearch',
                        getItems() {
                            return NextApi.api
                                .getSearchBarSetting(_spaceTypeName, {
                                    platform: 'web',
                                    region: _region,
                                    lang: _lang,
                                    group: _group,
                                    device: _deviceType
                                })
                                .then((res) => {
                                    return (
                                        res.data?.Data?.popular?.place?.map(
                                            (loc) => {
                                                return loc
                                            }
                                        ) ?? []
                                    )
                                })
                        },
                        onSelect({ state, item }) {
                            state.query = item.query
                            _closeAutocompletePanel()
                            _onSuggestionSelected(item, false)
                        },
                        templates: {
                            item({ item, html }) {
                                return html`
                                    <div class="d-flex">
                                        <div
                                            class="chip-btn d-flex align-items-center justify-content-center button-active"
                                        >
                                            <span
                                                class="text-404040 text-s-bold"
                                                >${item.query}</span
                                            >
                                        </div>
                                    </div>
                                `
                            },
                            header({ items, html }) {
                                if (items.length === 0) {
                                    return null
                                }
                                return html`
                                    <div class="aa-ItemWrapper">
                                        <span className="aa-SourceHeaderTitle">
                                            ${popularSearchTitle}
                                        </span>
                                    </div>
                                `
                            }
                        }
                    },
                    {
                        sourceId: 'nearbySearch',
                        getItems() {
                            return [
                                {
                                    id: 'search_nearby_button',
                                    label: _searchNearbyButtonTitle
                                }
                            ]
                        },
                        onSelect({ state }) {
                            state.query = _currentLocationTitle
                            _onSearchNearbyClicked()
                        },
                        templates: {
                            item({ item, html }) {
                                return html`
                                    <div class="d-flex">
                                        <div
                                            class="search-nearby-btn button-active d-flex align-items-center font-weight-bold text-16 position-relative px-2"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <img
                                                    src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/717f82de-5d1e-409f-12f0-58f3177f4e00/icon"
                                                    height="20"
                                                    width="20"
                                                    class="mr-1"
                                                />
                                                <span
                                                    class="search-nearby-title text-15"
                                                    style="white-space: nowrap;"
                                                >
                                                    ${_searchNearbyButtonTitle}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                `
                            }
                        }
                    }
                ])
            },
            render({ elements, render, html }, root) {
                const {
                    animPlaceholder,
                    nearbySearch,
                    popularSearch,
                    querySuggestion
                } = elements
                render(
                    html`
                        <div className="aa-PanelLayout aa-Panel--scrollable">
                            <div>
                                ${_isShowAnimSuggestion
                                    ? animPlaceholder
                                    : null}
                                ${nearbySearch} ${popularSearch}
                                ${querySuggestion}
                            </div>
                        </div>
                    `,
                    root
                )
            }
        })
        this.updateHandler = update
        this.setIsOpenHandler = setIsOpen
    },
    destroyed() {
        if (this.type === 'desktop') {
            window.removeEventListener('scroll', this.handleScrollMethod)
        }
    },
    methods: {
        // sendLogEvent(params) {
        //     analysis.sendViewSearchResult(params.space, params.f_date, params.q)
        // },
        getCurrentLocation() {
            this.$emit('getCurrentLocation')
        },
        onSearchNearbyClicked() {
            setTimeout(() => {
                if (this.isSpaceTypeLoaded) {
                    this.getCurrentLocation()
                }
            }, 10)
        },
        closeAutocompletePanel() {
            this.$emit('close')
        },
        setSuggestionSelected(item, isQuerySuggestion) {
            this.$emit(
                'setSuggestionSelected',
                item.query,
                item.category,
                item.location_hierarchies,
                item._id,
                isQuerySuggestion
            )
        },
        setInput(item) {
            if (item === AUTOCOMPLETE_RESET_CLICK) {
                this.$emit('setInput', '', true)
            } else if (
                // TODO: Hotfix for this.type === 'desktop' returned true even in mobile screen size
                this.type === 'desktop' &&
                !this.isMobileScreenSizeGlobal() &&
                item
            ) {
                this.$emit('setInput', item)
            } else if (
                this.type === 'mobile' &&
                this.isMobileScreenSizeGlobal() &&
                item
            ) {
                this.$emit('setInput', item)
            }
        },
        refreshAutoComplete(selectedSpaceType = 'rest', newQuery = '') {
            if (this.updateHandler) {
                const popularSearchTitle = this.$t('search_bar.popular_search')
                const debounced = debouncePromise(
                    (items) => Promise.resolve(items),
                    300
                )
                // variables and methods require 'this' scope
                const _lang = this.paramsLang
                const _region = this.paramsRegion
                const _algoliaLang = this.algoliaLang
                const _group = this.prefGroup
                const _spaceTypeName = this.spaceTypeName
                const _deviceType = this.type
                const _isShowPlaceholderSuggestion = this
                    .isShowPlaceholderSuggestion
                let _currentLocationTitle = this.inputWords
                const _searchConfig = this.searchConfig
                if (newQuery) {
                    _currentLocationTitle = newQuery
                }
                const _searchNearbyButtonTitle = this.$t(
                    'search_bar.search_nearby'
                )
                const _closeAutocompletePanel = () => {
                    this.closeAutocompletePanel()
                }
                const _onSuggestionSelected = (item, isQuerySuggestion) => {
                    this.setSuggestionSelected(item, isQuerySuggestion)
                }
                const _onSearchNearbyClicked = () => {
                    this.onSearchNearbyClicked()
                }
                const _currAnimItems = () => {
                    return this.querySource === 'es'
                        ? []
                        : [this.animatedPlaceholders[this.animIndex]]
                }
                this.updateHandler({
                    initialState: {
                        query: _currentLocationTitle
                    },
                    getSources: () => {
                        return debounced([
                            {
                                sourceId: 'animPlaceholder',
                                getItems() {
                                    return _isShowPlaceholderSuggestion
                                        ? _currAnimItems()
                                        : []
                                },
                                onSelect({ state, item }) {
                                    state.query = item.query
                                    _closeAutocompletePanel()
                                    _onSuggestionSelected(item, true)
                                },
                                templates: {
                                    item({ item, html }) {
                                        return html`
                                            <p class="text-15">
                                                ${item.query}
                                            </p>
                                        `
                                    }
                                }
                            },
                            {
                                sourceId: 'querySuggestion',
                                async getItems({query}) {
                                    const searchConfigRes = await NextApi.api.getSearchConfig(_spaceTypeName, {
                                        platform: 'web',
                                        region: _region,
                                        lang: _lang,
                                    })
                                    const searchConfig = searchConfigRes.data.Data
                                    const suggestions = await executeAlgoliaSearch(
                                        computeSuggestionIndex(
                                            _region,
                                            getSpaceTypeId(
                                                _spaceTypeName
                                            ),
                                            process.env.TYPESENSE_ENV
                                        ),
                                        {
                                            q: query === '' ? '*' : query,
                                            filter_by: computeSuggestionFilter(
                                                {
                                                    query,
                                                    algoliaLang: _algoliaLang,
                                                    group: _group,
                                                    region: _region
                                                }
                                            ),
                                            per_page: 5,
                                            queryParameters: {
                                                region: _region,
                                                query_suggestion: true
                                            },
                                            query_by: searchConfig.search.suggestions.query_by,
                                            sort_by: searchConfig.search.suggestions.sort_by_order.join(','),
                                        },
                                        true
                                    )
                                    return suggestions.hits
                                },
                                onSelect({ state, item }) {
                                    state.query = item.query
                                    _closeAutocompletePanel()
                                    _onSuggestionSelected(item, true)
                                },
                                templates: {
                                    item({ item, html }) {
                                        return html`
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <img
                                                    src="${getSuggestionIcon(
                                                        _spaceTypeName,
                                                        item.category
                                                    )}"
                                                    height="20"
                                                    width="20"
                                                    alt="category"
                                                    style="display: inline-block;"
                                                />
                                                <div class="ml-4">
                                                    <p class="text-15">
                                                        ${item.query}
                                                    </p>
                                                    <p class="text-12">
                                                        ${// eslint-disable-next-line standard/computed-property-even-spacing
                                                        item.category_text?.[
                                                            _algoliaLang
                                                        ] ?? ''}
                                                    </p>
                                                </div>
                                            </div>
                                        `
                                    }
                                }
                            },
                            {
                                sourceId: 'popularSearch',
                                getItems() {
                                    return NextApi.api
                                        .getSearchBarSetting(
                                            selectedSpaceType,
                                            {
                                                platform: 'web',
                                                region: _region,
                                                lang: _lang,
                                                group: _group,
                                                device: _deviceType
                                            }
                                        )
                                        .then((res) => {
                                            return (
                                                res.data?.Data?.popular?.place?.map(
                                                    (loc) => {
                                                        return loc
                                                    }
                                                ) ?? []
                                            )
                                        })
                                },
                                onSelect({ state, item }) {
                                    state.query = item.query
                                    _closeAutocompletePanel()
                                    _onSuggestionSelected(item, false)
                                },
                                templates: {
                                    item({ item, html }) {
                                        return html`
                                            <div class="d-flex">
                                                <div
                                                    class="chip-btn d-flex align-items-center justify-content-center button-active"
                                                >
                                                    <span
                                                        class="text-404040 text-s-bold"
                                                        >${item.query}</span
                                                    >
                                                </div>
                                            </div>
                                        `
                                    },
                                    header({ items, html }) {
                                        if (items.length === 0) {
                                            return null
                                        }
                                        return html`
                                            <div class="aa-ItemWrapper">
                                                <span
                                                    className="aa-SourceHeaderTitle"
                                                >
                                                    ${popularSearchTitle}
                                                </span>
                                            </div>
                                        `
                                    }
                                }
                            },
                            {
                                sourceId: 'nearbySearch',
                                getItems() {
                                    return [
                                        {
                                            id: 'search_nearby_button',
                                            label: _searchNearbyButtonTitle
                                        }
                                    ]
                                },
                                onSelect({ state }) {
                                    state.query = _currentLocationTitle
                                    _onSearchNearbyClicked()
                                },
                                templates: {
                                    item({ item, html }) {
                                        return html`
                                            <div class="d-flex">
                                                <div
                                                    class="search-nearby-btn button-active d-flex align-items-center font-weight-bold text-16 position-relative px-2"
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                    >
                                                        <img
                                                            src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/717f82de-5d1e-409f-12f0-58f3177f4e00/icon"
                                                            height="20"
                                                            width="20"
                                                            class="mr-1"
                                                        />
                                                        <span
                                                            class="search-nearby-title text-15"
                                                            style="white-space: nowrap;"
                                                        >
                                                            ${_searchNearbyButtonTitle}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        `
                                    }
                                }
                            }
                        ])
                    }
                })
            }
        },
        handleScrollMethod() {
            if (this.setIsOpenHandler) this.setIsOpenHandler(false)
        },
        onAutoCompleteSubmit() {
            this.$emit('onLocationSearchEnter')
        },
        updateAnimateIndex(index) {
            this.animIndex = index
        }
    }
}
</script>
<style scoped>
.input-container {
    /*https://getcssscan.com/css-box-shadow-examples #28*/
    /* DO NOT remove next line, for input container debug */
    /*box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;*/
    /*padding: 27px;*/
    /*width: 75vw;*/
}
@media (max-width: 414px) {
    .input-container {
        width: 100%;
    }
}
.search-nearby-btn {
    border-color: var(--primary);
    background-color: var(--white);
    position: absolute;
    left: 36px;
    top: 72px;
    z-index: 10000;
}
.num-ppl-field >>> .dropdown-item,
.date-field >>> .dropdown-item {
    white-space: normal !important;
    pointer-events: auto !important;
    color: black;
    cursor: auto !important;
}
.search-icon {
    position: absolute;
    z-index: 5;
    width: 16px;
    height: 16px;
}
.detached-placeholder-btn {
    position: absolute;
    left: 48px;
    /*z-index: 1050;*/
    pointer-events: none;
    color: black;
    font-weight: 400;
}
.landing-animated-text {
    font-size: 15px;
}
.search-animated-text {
    font-size: 14px;
}
@media (max-width: 992px) {
    .search-nearby-btn {
        top: 62px;
        left: 40px;
        padding: 0;
    }
    .landing-animated-text {
        font-size: 14px;
    }
}
</style>
