<template>
    <div v-if="starRating" class="d-flex align-items-end">
        <div class="d-flex align-items-center">
            <StarSvg
                v-for="(_, i) in getIntegerPart(starRating)"
                :key="i"
                fill-color="#ffc000"
                height="15px"
                width="15px"
            />
            <HalfStarSvg
                v-if="getFractionalPart(starRating) === 0.5"
                height="14px"
                width="14px"
                :type="type"
            />
        </div>
    </div>
</template>
<script>
import StarSvg from '@/components/iconSvg/StarSvg'
import HalfStarSvg from '@/components/iconSvg/HalfStarSvg'

export default {
    name: 'StarRating',
    components: {
        StarSvg,
        HalfStarSvg
    },
    props: {
        starRating: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            default: 'mobile'
        }
    },
    methods: {
        getIntegerPart(floatNumber) {
            const splitNum = floatNumber.toString().split('.')
            return Number(splitNum[0])
        },
        getFractionalPart(floatNumber) {
            const splitNum = floatNumber.toString().split('.')
            return Number('0.' + splitNum[1])
        }
    }
}
</script>
