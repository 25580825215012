<template>
    <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <ellipse
            cx="10.7241"
            cy="10.3328"
            rx="10"
            ry="10.2215"
            fill="white"
            fill-opacity="0.8"
        />
    </svg>
</template>

<script>
export default {
    name: 'FavHeartBg'
}
</script>
