<template>
    <div
        v-if="content"
        class="text-606060 text-12 text-reg content-text position-relative w-100"
    >
        <span class="position-absolute" style="margin-top: 0.1rem;">
            <TopCommentSvg style="height: 20px; width: 20px;" />
        </span>
        <p class="content-limit">
            {{ content }}
        </p>
    </div>
</template>

<script>
import TopCommentSvg from '@/components/iconSvg/TopCommentSvg'

export default {
    name: 'SpaceCardTopComment',
    components: {
        TopCommentSvg
    },
    props: {
        content: {
            type: String,
            required: false,
            default: undefined
        }
    }
}
</script>

<style scoped>
.content-text {
    padding-left: 28px;
}
.content-text span {
    left: 0;
}
.content-limit {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
