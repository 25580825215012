<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        :fill="fillColor"
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M0 0h24v24H0z" fill="none" />
        <path
            d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        />
    </svg>
</template>
<script>
export default {
    name: 'StarSvg',
    props: {
        fillColor: {
            type: String,
            required: false,
            default: '#000000'
        }
    }
}
</script>
