import { render, staticRenderFns } from "./StyledRightChevronSvg.vue?vue&type=template&id=4c7cf268&scoped=true"
import script from "./StyledRightChevronSvg.vue?vue&type=script&lang=js"
export * from "./StyledRightChevronSvg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7cf268",
  null
  
)

export default component.exports