<template>
    <svg
        width="7"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.73077 5.61713C6.62767 4.32585 6.00274 3.51666 5.45142 2.80259C4.94091 2.14152 4.5 1.57065 4.5 0.72854C4.5 0.660898 4.46063 0.599073 4.39819 0.568076C4.33556 0.536892 4.2603 0.541999 4.20337 0.581807C3.37537 1.1518 2.68451 2.11247 2.44318 3.02909C2.27565 3.66725 2.25348 4.38466 2.25037 4.85848C1.48572 4.70136 1.31251 3.60103 1.31068 3.58904C1.30206 3.53196 1.26582 3.4823 1.21308 3.45517C1.1598 3.4284 1.09717 3.42646 1.04316 3.45218C1.00306 3.47085 0.058957 3.93234 0.00402533 5.77494C0.000175716 5.83624 0 5.89772 0 5.95919C0 7.7493 1.51409 9.20581 3.375 9.20581C3.37757 9.20598 3.38031 9.20633 3.38251 9.20581C3.38324 9.20581 3.38397 9.20581 3.38488 9.20581C5.24122 9.20068 6.75 7.74614 6.75 5.95919C6.75 5.86918 6.73077 5.61713 6.73077 5.61713ZM3.375 8.84507C2.75463 8.84507 2.25 8.32793 2.25 7.69224C2.25 7.67058 2.24982 7.64873 2.25146 7.62196C2.25896 7.35388 2.31189 7.17087 2.36994 7.04915C2.47871 7.27391 2.67316 7.48052 2.98902 7.48052C3.09266 7.48052 3.17653 7.39984 3.17653 7.30015C3.17653 7.04335 3.18203 6.74707 3.24849 6.47968C3.30764 6.2426 3.44899 5.99036 3.62807 5.78816C3.70772 6.05061 3.863 6.26303 4.01462 6.47035C4.2316 6.76696 4.4559 7.07364 4.49527 7.5966C4.49764 7.62759 4.50004 7.65878 4.50004 7.69224C4.5 8.32791 3.99537 8.84507 3.375 8.84507Z"
            fill="#FFC000"
        />
    </svg>
</template>

<script>
export default {
    name: 'MostBookSvg'
}
</script>

<style scoped></style>
