<template>
    <svg
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            v-if="isFilled"
            d="M13.5976 2.12935C13.2375 1.74487 12.8002 1.43784 12.3131 1.22761C11.8261 1.01739 11.3 0.908552 10.7679 0.907962H10.7255C9.91417 0.909089 9.12669 1.17747 8.4892 1.67012C8.2059 1.88688 7.94976 2.13583 7.72613 2.41174C7.50315 2.13583 7.24759 1.88688 6.96481 1.67012C6.32772 1.17654 5.5401 0.907511 4.72849 0.90625H4.68607C4.15306 0.907055 3.62602 1.01649 3.13834 1.22761C2.65065 1.43874 2.21293 1.74697 1.8529 2.13278C1.10739 2.95002 0.704406 4.01384 0.724864 5.11066C0.757172 6.36632 1.25567 7.56713 2.12663 8.48732C3.17478 9.62127 4.31425 10.6705 5.53398 11.6249L5.65022 11.7236C6.09016 12.0916 6.58008 12.5012 7.08453 12.9353C7.26153 13.0874 7.48859 13.1715 7.7238 13.1721C7.95887 13.1718 8.18591 13.0881 8.36308 12.9365C8.88613 12.4864 9.3929 12.0631 9.84447 11.6848L9.91304 11.6278C11.1337 10.6722 12.274 9.62158 13.3227 8.48618C14.1934 7.56547 14.6915 6.36426 14.7233 5.10838C14.7445 4.01151 14.3424 2.94735 13.5976 2.12935Z"
            fill="#ffc000"
        />
        <path
            v-else
            d="M13.5976 1.44576C13.2375 1.06128 12.8002 0.754242 12.3131 0.54402C11.8261 0.333798 11.3 0.224958 10.7679 0.224368H10.7255C9.91417 0.225495 9.12669 0.493876 8.4892 0.986525C8.2059 1.20329 7.94976 1.45223 7.72613 1.72814C7.50315 1.45223 7.24758 1.20329 6.96481 0.986525C6.32772 0.492948 5.5401 0.223917 4.72849 0.222656H4.68607C4.15306 0.223461 3.62602 0.332892 3.13834 0.54402C2.65065 0.755148 2.21293 1.06337 1.8529 1.44918C1.10739 2.26642 0.704406 3.33025 0.724864 4.42707C0.757172 5.68272 1.25567 6.88354 2.12663 7.80372C3.17478 8.93767 4.31425 9.98694 5.53398 10.9413L5.65022 11.04C6.09016 11.408 6.58008 11.8176 7.08453 12.2517C7.26153 12.4038 7.48859 12.4879 7.7238 12.4885C7.95887 12.4882 8.18591 12.4045 8.36308 12.2529C8.88613 11.8028 9.3929 11.3795 9.84447 11.0012L9.91304 10.9442C11.1337 9.98859 12.274 8.93799 13.3227 7.80258C14.1934 6.88188 14.6915 5.68066 14.7233 4.42479C14.7445 3.32792 14.3424 2.26376 13.5976 1.44576ZM13.5825 4.41566C13.5532 5.4075 13.1521 6.35386 12.4562 7.07351C11.4464 8.16131 10.3501 9.1687 9.17787 10.0862C8.73619 10.4547 8.24162 10.8695 7.72497 11.3127C7.22749 10.8872 6.75036 10.4872 6.32262 10.1284L6.27032 10.0856C5.09866 9.16784 4.00303 8.16026 2.99372 7.07237C2.29865 6.35392 1.89821 5.40891 1.86917 4.41851C1.851 3.60479 2.14634 2.8143 2.69617 2.20506C2.95021 1.93409 3.25871 1.71774 3.6022 1.56967C3.94569 1.4216 4.31672 1.34503 4.69188 1.34478H4.72326C5.28262 1.3456 5.82553 1.53061 6.26509 1.87019C6.61804 2.14963 6.9157 2.49035 7.14322 2.87537C7.20319 2.97419 7.28829 3.05602 7.39016 3.11285C7.49204 3.16968 7.60721 3.19956 7.72439 3.19956C7.84156 3.19956 7.95673 3.16968 8.05861 3.11285C8.16049 3.05602 8.24558 2.97419 8.30555 2.87537C8.5341 2.49087 8.83298 2.15107 9.18717 1.87305C9.62697 1.53209 10.1709 1.34641 10.7313 1.34593H10.761C11.1355 1.34586 11.5059 1.42185 11.8491 1.56911C12.1923 1.71637 12.5007 1.93173 12.7549 2.20164C13.3049 2.81106 13.6004 3.6017 13.5825 4.41566Z"
            fill="#ffc000"
        />
    </svg>
</template>

<script>
export default {
    name: 'FavHeart',
    props: {
        isFilled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style scoped></style>
