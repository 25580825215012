<template>
    <div class="d-flex w-100">
        <SwiperContainer
            v-if="spaceTypeInfo.length > 0"
            :option="swiperOption"
            :name="'space-type-list'"
            :type="type"
        >
            <b-button
                v-for="(item, index) in spaceTypeInfo"
                :key="index"
                @click="setSpaceType(item)"
                class="p-0 swiper-slide"
                variant="btn-sm"
            >
                <div
                    :class="[
                        services === item.space_type
                            ? 'space-btn-selected'
                            : 'space-btn'
                    ]"
                    class="pb-2"
                >
                    <ImageContainer
                        :alt="item.space_type_string"
                        :src="item.icon"
                        class="home-svg-icon"
                        height="50"
                        width="50"
                    />
                    <div class="text-14" style="font-weight: 500">
                        {{ item.space_type_string }}
                    </div>
                </div>
            </b-button>
        </SwiperContainer>
    </div>
</template>

<script>
import SwiperContainer from '@/components/ui/SwiperContainer'
import ImageContainer from "@/components/ui/ImageContainer.vue";
export default {
    name: 'SpaceTypeList',
    components: {ImageContainer, SwiperContainer },
    props: {
        type: {
            type: String,
            default: ''
        },
        selectedSpaceType: {
            type: String,
            default: 'rest'
        }
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 6,
                spaceBetween: 0,
                freeMode: true
            },
            services: ''
        }
    },
    computed: {
        spaceTypeInfo() {
            return this.$store.getters['api/spaceTypeInfo']
        },
        space() {
            return (
                this.$route.params.space ??
                this.globalSpace(this.selectedSpaceType)
            )
        }
    },
    mounted() {
        this.setDefaultService()
    },
    methods: {
        setDefaultService() {
            const type = this.spaceTypeInfo.find((item) => {
                return item.appclip_space_type === this.space
            })
            this.selectService(type)
        },
        selectService(item) {
            this.services = item.space_type
        },
        setSpaceType(item) {
            this.selectService(item)
            this.$emit('setSpaceType', item)
        }
    }
}
</script>

<style scoped>
.space-btn-selected {
    filter: invert(74%) sepia(74%) saturate(481%) hue-rotate(352deg)
        brightness(88%) contrast(106%);
    border-bottom: 2px solid;
}
.space-btn {
    filter: invert(72%) sepia(31%) saturate(0%) hue-rotate(287deg)
        brightness(94%) contrast(105%);
}
@media (max-width: 414px) {
    .home-svg-icon {
        height: auto;
        width: 26px;
        margin-bottom: 2px;
    }
}
/deep/ .swiper-button-prev,
/deep/ .swiper-button-next {
    filter: invert(72%) sepia(31%) saturate(0%) hue-rotate(287deg)
        brightness(94%) contrast(105%) !important;
    top: 60%;
    background-size: 8px 17px;
    width: 8px;
}

/deep/ .swiper-button-prev:hover,
/deep/ .swiper-button-next:hover {
    filter: invert(72%) sepia(31%) saturate(0%) hue-rotate(287deg)
        brightness(94%) contrast(105%) !important;
}

/deep/ .swiper-button-prev {
    left: 0;
}
/deep/ .swiper-button-next {
    right: 0;
}
</style>
