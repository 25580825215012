<template>
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 15 15">
        <style type="text/css">
            .star0 {
                fill: none;
            }
            .star2 {
                fill: none;
                stroke: #ffc000;
                stroke-width: 0.5;
                stroke-miterlimit: 10;
            }
        </style>
        <g>
            <path class="star0" d="M0,0h15v15H0V0z" />
            <path class="star0" d="M0,0h15v15H0V0z" />
        </g>
        <g>
            <path
                class="star2"
                d="M7.8,11.4l2.9,1.8c0.4,0.2,0.8-0.1,0.7-0.6l-0.8-3.5c0-0.2,0-0.4,0.2-0.5l2.6-2.3c0.3-0.3,0.2-0.9-0.3-0.9
		L9.7,5.2C9.5,5.2,9.3,5,9.3,4.9L7.9,1.6c-0.2-0.4-0.7-0.4-0.9,0L5.7,4.9C5.7,5,5.5,5.2,5.3,5.2L1.9,5.5c-0.4,0-0.6,0.6-0.3,0.9
		l2.6,2.3C4.4,8.8,4.4,9,4.4,9.2l-0.8,3.5c-0.1,0.4,0.4,0.8,0.7,0.6l2.9-1.8C7.4,11.3,7.6,11.3,7.8,11.4z"
            />
            <g>
                <defs>
                    <rect
                        :id="`SVGID_00000106842789468742997620000015243489861022164886_${type}`"
                        x="1.5"
                        y="1.3"
                        width="6"
                        height="12"
                    />
                </defs>
                <clipPath
                    :id="`SVGID_00000050654647169770090270000011015677156035371181_${type}`"
                >
                    <use
                        :xlink:href="`#SVGID_00000106842789468742997620000015243489861022164886_${type}`"
                        style="overflow:visible;"
                    />
                </clipPath>
                <path
                    :style="`clip-path:url(#SVGID_00000050654647169770090270000011015677156035371181_${type}); fill:#FFC000;`"
                    d="M7.8,11.4l2.9,1.8
			c0.4,0.2,0.8-0.1,0.7-0.6l-0.8-3.5c0-0.2,0-0.4,0.2-0.5l2.6-2.3c0.3-0.3,0.2-0.9-0.3-0.9L9.7,5.2C9.5,5.2,9.3,5,9.3,4.9L7.9,1.6
			c-0.2-0.4-0.7-0.4-0.9,0L5.7,4.9C5.7,5,5.5,5.2,5.3,5.2L1.9,5.5c-0.4,0-0.6,0.6-0.3,0.9l2.6,2.3C4.4,8.8,4.4,9,4.4,9.2l-0.8,3.5
			c-0.1,0.4,0.4,0.8,0.7,0.6l2.9-1.8C7.4,11.3,7.6,11.3,7.8,11.4z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'HalfStarSvg',
    props: {
        type: {
            type: String,
            default: 'mobile'
        }
    }
}
</script>

<style scoped></style>
