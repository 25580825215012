<template>
    <b-button
        :disabled="isDisabled"
        :class="isDisabled ? 'disabled-increment-btn' : 'increment-btn'"
        @click="buttonClicked"
        variant="transparent"
        class="text-mid px-2 py-1 text-13 d-flex align-items-center justify-content-center"
        style="border-radius: 50px; font-weight: 600; width: 30px; height: 30px; border: 1px solid !important;"
    >
        <div class="text-15"><slot /></div>
    </b-button>
</template>

<script>
export default {
    name: 'IncrementButton',
    props: {
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        buttonClicked() {
            this.$emit('buttonClicked')
            this.$emit('updateTotalPax')
        }
    }
}
</script>

<style scoped>
.increment-btn {
    color: #606060;
    border-color: #606060;
}
.disabled-increment-btn {
    color: #e1e1e1;
    border-color: #e1e1e1;
    cursor: not-allowed;
}
</style>
