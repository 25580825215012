<template>
    <div v-if="groupList.length > 0">
        <p class="text-16 text-s-bold">
            {{ $t('header.current_area') }}
        </p>
        <b-button
            @click="onChangeRegionClicked"
            variant="transparent"
            class="d-flex align-items-center justify-content-between mt-3 px-3 py-3 w-100 change-area-button button-active-filter"
        >
            <p class="text-15 text-s-bold text-afafaf text-left">
                {{ currentGroupText }}
            </p>
            <ChevronRightSvg />
        </b-button>
        <div class="mt-4 mb-4 change-area-separator w-100 bg-afafaf" />
    </div>
</template>

<script>
import _filter from 'lodash/filter'
import { MODAL_MODE } from '@/store/general'
import ChevronRightSvg from '@/components/iconSvg/ChevronRightSvg'

export default {
    name: 'ChangeGroupButton',
    components: {
        ChevronRightSvg
    },
    computed: {
        groupList() {
            const region = this.paramsRegion
            const currentRegion = _filter(this.regionList, [
                'country_code',
                region
            ])
            if (currentRegion.length <= 0) {
                return []
            }
            return currentRegion[0].groups
        },
        currentGroupText() {
            const selectedRegionObj = this.currentRegionObj
            if (selectedRegionObj && selectedRegionObj.groups?.length > 0) {
                const currentArea = _filter(selectedRegionObj.groups, [
                    'slug',
                    this.paramsGroup
                ])
                return (
                    currentArea?.[0]?.name ??
                    this.$t('header.current_area_placeholder')
                )
            } else {
                return this.$t('header.current_area_placeholder')
            }
        }
    },
    methods: {
        onChangeRegionClicked() {
            this.$store.dispatch('general/SET_LOCALE_MODAL_VISIBLE', {
                isVisible: true,
                mode: MODAL_MODE.GROUP
            })
        }
    }
}
</script>

<style scoped>
.change-area-button {
    border-radius: 8px;
    border: 1px solid #dddcda;
}
.change-area-separator {
    height: 1px;
}
</style>
