<template>
    <div class="position-relative">
        <div class="gradient-back"></div>
    </div>
</template>
<script>
export default {
    name: 'GradientOpacity'
}
</script>
<style scoped>
.gradient-back {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
}
</style>
