<template>
    <div class="w-100">
        <div v-if="anyPeriods?.length > 0">
            <b-btn
                v-for="(item, index) in anyPeriods"
                :key="`any_${index}`"
                :class="
                    selectedCheckInTime === $t('search_bar.any_time')
                        ? 'time-btn-selected'
                        : 'any-time-btn'
                "
                class="mx-2 px-3 time-btn"
                variant="transparent"
                @click="onAnyTimeClicked"
            >
                <p class="text-13 text-mid">
                    {{ item.text }}
                </p>
            </b-btn>
        </div>
        <div class="d-flex mb-2 mx-2 align-items-center justify-content-between">
            <p
                :class="isHotel ? 'text-primary' : ''"
                class="text-14 text-s-bold"
            >
                {{
                    isHotel
                        ? $t('search_bar.select_multiple_periods')
                        : $t('search_bar.popular_start_time')
                }}
            </p>
            <b-button
                v-if="isShowClosePeriodButton"
                @click="onConfirmTimeClicked"
                class="close-btn bg-transparent border-0 text-292929 text-ikonoMini-4 d-none d-lg-block"
            >
                
            </b-button>
        </div>
        <div v-if="checkInPeriods?.length > 0" class="mt-2 mt-lg-0">
            <div class="d-flex">
                <p class="text-13 text-mid mx-2">{{ $t('search_bar.daycation') }}</p>
                <InfoSvg
                    id="tooltip-target"
                    style="fill: #b5b3ab;"
                    class="d-none d-lg-block ml-1"
                />
                <b-tooltip target="tooltip-target" triggers="hover">
                    {{ $t('search_bar.daycation_desc') }}
                </b-tooltip>
            </div>
            <p class="d-block d-lg-none text-12 text-reg mx-2">
                {{ $t('search_bar.daycation_desc') }}
            </p>
            <b-btn
                v-for="(item, index) in daycationPeriods"
                v-if="!checkPeriodButtonDisabled(item.value)"
                :key="`daycation_${index}`"
                :class="computeSelectStatusStyles(item)"
                class="mx-2 px-3 mt-2"
                variant="transparent"
                @click="setPeriod(item)"
            >
                <p class="text-13 text-mid">
                    {{
                        insertTmrDateIfNeeded(item.value) + item.text + `(${item.start_time}-${item.end_time})`
                    }}
                </p>
            </b-btn>
            <p class="text-13 text-mid mx-2 mt-2">{{ $t('search_bar.overnight') }}</p>
            <b-btn
                v-for="(item, index) in overnightPeriods"
                v-if="!checkPeriodButtonDisabled(item.value)"
                :key="`overnight_${index}`"
                :class="computeSelectStatusStyles(item)"
                style="height: auto;"
                class="text-13 text-mid mx-2 px-3 mt-2"
                variant="transparent"
                @click="setPeriod(item)"
            >
                {{
                    insertTmrDateIfNeeded(item.value) + item.text
                }}
            </b-btn>
        </div>
        <div v-else class="d-flex">
            <b-btn
                :class="
                    selectedCheckInTime === $t('search_bar.any_time')
                        ? 'time-btn-selected'
                        : 'any-time-btn'
                "
                @click="onAnyTimeClicked"
                class="text-13 text-mid mx-2 px-3 time-btn"
                variant="transparent"
            >
                {{ $t('search_bar.any_start_time') }}
            </b-btn>
            <b-btn
                v-if="isAsapTimeButtonVisible"
                :class="
                    selectedCheckInTime === $t('search_bar.asap_time') ||
                    selectedCheckInTime === $t('search_bar.asap_time_non_hotel')
                        ? 'time-btn-selected'
                        : 'any-time-btn'
                "
                @click="onAsapTimeClicked"
                class="text-13 text-mid mx-2 px-3 time-btn"
                variant="transparent"
            >
                {{ $t('search_bar.asap_time_non_hotel') }}
            </b-btn>
        </div>
        <b-btn
            v-if="checkInPeriods?.length <= 0"
            v-for="(item, index) in popularCheckInTime"
            :key="`popular-${index}`"
            :class="selectedCheckInTime === item ? 'time-btn-selected' : ''"
            @click="onButtonClicked(item, 'default')"
            class="text-13 text-mid mx-2 px-2 text-center time-btn check-in-time-btn"
            variant="transparent"
        >
            {{ item }}
        </b-btn>
        <div class="mx-2 mt-2">
            <p class="text-14 text-primary text-s-bold">
                {{ $t('search_bar.select_one_time') }}
            </p>
            <p class="text-12 text-s-bold mt-2">
                {{
                    isHotel
                        ? $t('search_bar.specific_check_in_time')
                        : $t('search_bar.specific_start_time')
                }}
            </p>
        </div>
        <b-btn
            v-for="(item, index) in otherCheckInTime"
            :key="`other-${index}`"
            :class="selectedCheckInTime === item ? 'time-btn-selected' : ''"
            @click="onButtonClicked(item, 'default')"
            class="text-13 text-mid mx-2 px-2 text-center time-btn check-in-time-btn"
            variant="transparent"
        >
            {{ item }}
        </b-btn>
    </div>
</template>

<script>
import dateServices from '@/services/dateServices'
import analysis from '@/services/analysis'
import InfoSvg from '@/components/iconSvg/InfoSvg'
import { DATE_TIME } from '@/lib-flow-main-search/src/constants'
import {
    computeConflictPeriodOptions,
    isPeriodButtonDisabled,
    insertTmrDateIfAfter8,
    sortedDaycationPeriods,
    mapTimePeriodToTrackingValue
} from '@/lib-flow-main-search/src/utils'
import { getGAEventName } from '@/utils/analysis'

export default {
    name: 'CheckInTimeSearch',
    components: { InfoSvg },
    props: {
        selectedDate: {
            type: String,
            default: '',
            required: true
        },
        selectedCheckInTimeMobileView: {
            type: String,
            default: undefined,
            required: false
        },
        selectedService: {
            type: String,
            default: undefined,
            required: false
        }
    },
    data() {
        return {
            checkInTime: [],
            otherCheckInTime: [],
            selectedCheckInTime: '',
            DATE_TIME: DATE_TIME,
            selectedPeriods: [],
            disallowedPeriods: []
        }
    },
    computed: {
        region() {
            return this.$route.params.region
        },
        popularCheckInTime() {
            return this.$store.getters['api/popularCheckInTime'].filter(
                (time) => {
                    const parsedTime = dateServices.getTimestampFromDateTime(
                        this.selectedDate,
                        time,
                        this.region
                    )
                    return parsedTime > new Date().valueOf()
                }
            )
        },
        checkInPeriods() {
            return this.$store.getters['api/checkInPeriods']
        },
        daycationPeriods() {
            return sortedDaycationPeriods(
                this.selectedDate,
                this.paramsRegion,
                this.checkInPeriods
            )
        },
        overnightPeriods() {
            return this.checkInPeriods.filter(_ => _.type === 'overnight')
        },
        anyPeriods() {
            return this.$store.getters['api/checkInPeriods'].filter(_ => _.type === 'any')
        },
        space() {
            return this.$route.params.space
        },
        isHotel() {
            return this.selectedService === 'rest' || this.paramsSpace === 'hotel'
        },
        isLandingPage() {
            return this.isHomePage || this.isMiniSite
        },
        isUpdateDefaultTime() {
            return (
                (!this.isLandingPage && !this.$route.query.time) ||
                (this.isLandingPage && !this.selectedCheckInTime)
            )
        },
        isAsapTimeButtonVisible() {
            return this.selectedDate === dateServices.getToday()
        },
        isShowClosePeriodButton() {
            return this.$route.name === 'region-locale-space'
        }
    },
    watch: {
        selectedDate: {
            immediate: false,
            handler(newVal) {
                this.otherCheckInTime = dateServices.getTimeslotsIntervals(
                    newVal,
                    15
                )
                const newTimeValue = dateServices.getDefaultTimePickerValue(
                    newVal,
                    this.region
                )
                if (newTimeValue === dateServices.ANY_CHECK_IN_TIME) {
                    this.onAnyTimeClicked(false, false, true)
                } else {
                    this.selectedCheckInTime = newTimeValue
                    this.$emit(
                        'onCheckInTimeClicked',
                        newTimeValue,
                        false,
                        false
                    )
                }
            }
        }
    },
    mounted() {
        if (this.selectedCheckInTimeMobileView) {
            this.selectedCheckInTime = this.selectedCheckInTimeMobileView
        }
        this.calculateOtherCheckInTime()
        if (this.selectedCheckInTimeMobileView === 'any_time')
            this.onAnyTimeClicked(true, true, true)
        if (this.isUpdateDefaultTime) {
            const newTimeValue = dateServices.getDefaultTimePickerValue(
                this.selectedDate,
                this.region
            )
            this.selectedCheckInTime = newTimeValue
            this.$emit('onCheckInTimeClicked', newTimeValue, false, false)
        }
    },
    methods: {
        onButtonClicked(time, type) {
            this.selectedCheckInTime = time
            this.selectedPeriods = []
            this.$emit('onCheckInTimeClicked', time)
            this.sendLogEventsItem(type, time)
        },
        onAsapTimeClicked(autoClose = true, promptPaxPicker = true) {
            this.selectedCheckInTime = this.$t('search_bar.asap_time_non_hotel')
            this.$emit(
                'onCheckInTimeClicked',
                DATE_TIME.ASAP_CHECK_IN_TIME,
                autoClose,
                promptPaxPicker
            )
            this.sendLogEventsItem('default', 'As soon as possible')
        },
        onAnyTimeClicked(autoClose = true, promptPaxPicker = true, autoSelect = false) {
            this.selectedPeriods = []
            this.selectedCheckInTime = this.$t('search_bar.any_time')
            this.$emit('setCheckInPeriod',
                [DATE_TIME.ANY_CHECK_IN_TIME],
                autoClose,
                promptPaxPicker
            )
            if (!autoSelect) {
                this.sendLogEventsItem('default', mapTimePeriodToTrackingValue('any_time'))
            }
        },
        calculateOtherCheckInTime() {
            this.otherCheckInTime = dateServices.getTimeslotsIntervals(
                this.selectedDate,
                15
            )
        },
        sendLogEventsItem(type, value) {
            const source = getGAEventName(this.landingPageName, 'SEARCH_RESULT_BAR', value)
            analysis.sendPopularCheckInTimeButtonClicked(source, type, value, this.selectedDate)
        },
        computeSelectStatusStyles(item) {
            return this.isPeriodDisallowed(item)
                ? 'period-btn-disallowed'
                : this.isPeriodSelected(item)
                    ? 'period-btn-selected'
                    : 'period-btn'
        },
        isPeriodSelected(item) {
            return this.selectedPeriods.includes(item.value)
        },
        isPeriodDisallowed(item) {
            if (this.disallowedPeriods.length > 0) {
                return this.disallowedPeriods.includes(item.value)
            }
            return false
        },
        setPeriod(item) {
            this.selectedCheckInTime = ''
            const now = new Date().valueOf()
            const today = dateServices.getToday()
            if (this.isPeriodDisallowed(item)) {
                this.selectedPeriods = [item.value]
            } else if (this.isPeriodSelected(item)) {
                const removeIndex = this.selectedPeriods.indexOf(item.value)
                this.selectedPeriods = this.selectedPeriods.filter((_, i) => i !== removeIndex)
            } else {
                this.selectedPeriods = [
                    ...this.selectedPeriods,
                    item.value
                ]
                this.sendLogEventsItem('default', mapTimePeriodToTrackingValue(item.value))
            }
            this.disallowedPeriods = computeConflictPeriodOptions(
                this.selectedPeriods,
                now,
                today,
                this.selectedDate,
                this.paramsRegion
            )
            this.$emit('setCheckInPeriod', this.selectedPeriods)
        },
        onConfirmTimeClicked() {
            this.$emit('onConfirmTimeClicked')
        },
        checkPeriodButtonDisabled(type) {
            return isPeriodButtonDisabled(type, this.region, this.selectedDate)
        },
        insertTmrDateIfNeeded(period) {
            return insertTmrDateIfAfter8(period, this.selectedDate, this.paramsRegion)
        }
    }
}
</script>

<style scoped>
.time-btn {
    border: 0;
    border-radius: 6px;
    height: 32px;
    margin-bottom: 8px;
}

.check-in-time-btn {
    width: 64px;
    background: #f3f2f0;
}

.period-btn-disallowed {
    text-decoration: line-through;
}

.time-btn-selected {
    background: var(--primary);
}

.any-time-btn {
    background: #f3f2f0;
}

.period-btn {
    background: white;
    border-radius: 18px;
    border: 1px solid #ffc000;
    color: #ffc000;
}

.period-btn-selected {
    background: #ffc000;
    border-radius: 18px;
    color: white;
}

.close-btn {
    font-size: 18px;
    color: #505050;
    transform: rotate(45deg);
}
</style>
