<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="21px"
        viewBox="0 96 960 960"
        width="21px"
        fill="#000000"
    >
        <path
            d="M182.152 981.978q-27.599 0-47.865-20.265-20.265-20.266-20.265-47.865V298.152q0-27.697 20.265-48.033 20.266-20.337 47.865-20.337H245v-60h69.065v60h331.87v-60H715v60h62.848q27.697 0 48.033 20.337 20.337 20.336 20.337 48.033v615.696q0 27.599-20.337 47.865-20.336 20.265-48.033 20.265H182.152Zm0-68.13h595.696V486H182.152v427.848Zm0-487.848h595.696V298.152H182.152V426Zm0 0V298.152 426Z"
        />
    </svg>
</template>

<script>
export default {
    name: 'CalendarSvg'
}
</script>

<style scoped></style>
