<template>
    <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_320_1456)">
            <path
                d="M7.77402 3.09061L7.41126 0.794922L6.75775 1.67284C5.13865 0.974869 3.20214 1.27478 1.86311 2.58621C0.196 4.21936 0.0492942 6.95401 1.46567 8.76983L2.17786 8.04459C1.15092 6.64319 1.26028 4.64469 2.50862 3.37143C3.48488 2.37627 4.89059 2.10635 6.11491 2.52895L5.50408 3.34962L7.77402 3.09061Z"
                fill="#FFC000"
            />
            <path
                d="M8.51553 3.03027L7.798 3.76097C8.93164 5.30687 8.68624 7.58074 7.0458 8.8022C6.12822 9.48654 4.93324 9.65013 3.87962 9.29296L4.49845 8.46139L2.22852 8.72041L2.59128 11.0161L3.24745 10.1354C4.88522 10.8334 6.84575 10.509 8.17944 9.15119C9.82254 7.48259 9.9319 4.83519 8.51553 3.03027Z"
                fill="#FFC000"
            />
            <path
                d="M5.00262 6.41992C5.0373 6.41992 5.06931 6.43273 5.10132 6.44042C5.06931 6.42505 5.0373 6.41992 5.00262 6.41992Z"
                fill="#F2BC1F"
            />
            <path
                d="M5.00263 3.8623L2.99677 5.3973H3.49823V7.44215H4.50117V6.93503C4.50117 6.65148 4.71989 6.41973 4.9973 6.41973C4.9973 6.41973 4.9973 6.41973 4.99997 6.41973C4.99997 6.41973 4.99997 6.41973 5.00263 6.41973C5.03731 6.41973 5.07199 6.42791 5.10133 6.44154C5.33072 6.49062 5.5041 6.68965 5.5041 6.93503V7.44215H6.50703V5.3973H7.0085L5.00263 3.8623Z"
                fill="#FFC000"
            />
        </g>
        <defs>
            <clipPath id="clip0_320_1456">
                <rect
                    width="10"
                    height="10.2215"
                    fill="white"
                    transform="translate(0 0.794922)"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'NewRenovateSvg'
}
</script>

<style scoped></style>
