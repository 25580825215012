<template>
    <div v-if="dataTags?.length > 0" class="d-flex flex-wrap">
        <div
            v-for="(tag, idx) in dataTags"
            :key="idx"
            class="d-flex data-tag align-items-center px-1 py-1 px-md-2 py-md-2 ml-0 ml-md-2 mt-1 mt-md-2"
        >
            <component :is="tagIcon(tag.type)" class="data-tag-icon mr-1" />
            <p class="text-s-bold text-12 text-606060 data-tag-text">
                {{ tag.content }}
            </p>
        </div>
        <div
            v-if="remainCount > 0"
            id="tooltip-remain-tags"
            class="data-tag px-3 py-2 mt-2"
            style="cursor: pointer;"
        >
            <p
                class="text-606060 text-12 text-s-bold"
                style="cursor: pointer; user-select: none;"
            >
                {{ '+' + remainCount }}
            </p>
        </div>
        <b-tooltip
            target="tooltip-remain-tags"
            triggers="hover"
            variant="light"
        >
            <div
                v-for="(tag, idx) in dataTags"
                :key="idx"
                class="d-flex data-tag align-items-center px-3 py-2 mr-2 mt-2"
                style="box-shadow: none;"
            >
                <component :is="tagIcon(tag.type)" class="data-tag-icon" />
                <p class="ml-1 text-s-bold text-12 text-606060 data-tag-text">
                    {{ tag.content }}
                </p>
            </div>
        </b-tooltip>
    </div>
</template>

<script>
import MostBookSvg from '@/components/iconSvg/dataTag/MostBookSvg'
import MostSaveSvg from '@/components/iconSvg/dataTag/MostSaveSvg'
import MostViewSvg from '@/components/iconSvg/dataTag/MostViewSvg'
import NewOpenSvg from '@/components/iconSvg/dataTag/NewOpenSvg'
import NewRenovateSvg from '@/components/iconSvg/dataTag/NewRenovateSvg'

export default {
    name: 'SpaceCardDataTags',
    components: {
        // eslint-disable-next-line vue/no-unused-components
        MostBookSvg,
        // eslint-disable-next-line vue/no-unused-components
        MostSaveSvg,
        // eslint-disable-next-line vue/no-unused-components
        MostViewSvg,
        // eslint-disable-next-line vue/no-unused-components
        NewOpenSvg,
        // eslint-disable-next-line vue/no-unused-components
        NewRenovateSvg
    },
    props: {
        dataTags: {
            required: false, // TODO: change to true when data available
            type: Array,
            default() {
                return [
                    {
                        content: 'Renovated in 2019',
                        type: 'new-renovate'
                    },
                    {
                        content: 'Newly opened in 2020',
                        type: 'new-open'
                    },
                    {
                        content: 'Most Viewed',
                        type: 'most-viewed'
                    },
                    {
                        content: 'Most Booked',
                        type: 'most-booked'
                    },
                    {
                        content: 'Most Saved',
                        type: 'most-saved'
                    },
                    {
                        content: 'Most Saved',
                        type: 'most-saved'
                    }
                ]
            }
        }
    },
    computed: {
        remainCount() {
            const tagsLength = this.dataTags?.length ?? 0
            return tagsLength - 5
        }
    },
    methods: {
        tagIcon(type) {
            if (type === 'new-renovate') {
                return 'new-renovate-svg'
            }
            if (type === 'new-open') {
                return 'new-open-svg'
            }
            if (type === 'most-viewed') {
                return 'most-view-svg'
            }
            if (type === 'most-booked') {
                return 'most-book-svg'
            }
            if (type === 'most-saved') {
                return 'most-save-svg'
            }
        }
    }
}
</script>

<style scoped>
.data-tag {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 10px 0 #1e1e1e24;
}
.data-tag-icon {
    height: 15px;
    width: 15px;
}
.data-tag-text {
    white-space: nowrap;
    user-select: none;
}
</style>
