<template>
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="8.20006"
            cy="8.20006"
            r="7.20006"
            stroke="#979797"
            stroke-width="1.5"
        />
        <path
            d="M19.0002 19.0001L15.4001 15.4"
            stroke="#979797"
            stroke-width="1.5"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'SearchSvg'
}
</script>
