<template>
    <div class="d-flex flex-column justify-content-between h-100">
        <client-only>
            <div
                class="bg-transparent mx-auto"
                style="max-width: 100%; width: 294px;"
            >
                <datepicker
                    :language="getDatePickerLocale()"
                    :inline="true"
                    :disabled-dates="getDisableDateList"
                    :value="selectedDate"
                    :day-cell-content="dayCellContent"
                    @selected="setDate"
                    class="vdp-datepicker"
                />
            </div>
            <div v-if="!disablePrices" class="text-13 mt-4 mx-3">
                {{ calendarPricesDescription }}
            </div>
        </client-only>
    </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty'
import dateServices from '@/services/dateServices'
import analysis from "@/services/analysis";
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'CalendarSearch',
    components: {},
    props: {
        selectedDate: {
            type: String,
            default: ''
        },
        disableDateList: {
            type: Object,
            default() {
                return {}
            }
        },
        isHighLightToday: {
            type: Boolean,
            default: true
        },
        disablePrices: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            textColor: '#212529',
            disabledDates: {
                min_date: dateServices.getDateByFormat(
                    new Date(
                        new Date().setFullYear(new Date().getFullYear() - 1)
                    )
                ),
                max_date: dateServices.getDateByFormat(
                    new Date(new Date().setDate(new Date().getDate() + 27))
                ),
                disable_date: {},
                calendar_prices: {}
            }
        }
    },
    computed: {
        searchBarSetting() {
            return this.$store.getters['api/searchBarSetting']
        },
        getDisableDateList() {
            return dateServices.getFormattedDateList(this.dateInfo)
        },
        calendarPrices() {
            return this.dateInfo.calendar_prices
        },
        calendarPricesDescription() {
            return this.dateInfo.calendar_prices_description
        },
        dateInfo() {
            /*
                Details -> disableDateList
                Global -> searchBarSetting
                Other -> disabledDates
            */
            return !_isEmpty(this.disableDateList)
                ? this.disableDateList
                : !_isEmpty(this.searchBarSetting)
                ? this.searchBarSetting
                : this.disabledDates
        }
    },
    methods: {
        setDate(date) {
            if (!this.isDetailsPage) {
                const source = getGAEventName(this.landingPageName)
                analysis.sendSelectCheckInDate({
                    source,
                    name: '',
                    slug: '',
                    date: dateServices.getDateByFormat(date)
                })
            }
            this.$emit('setDate', dateServices.getDateByFormat(date))
        },
        getDate(date) {
            return dateServices.getDateByFormat(new Date(date))
        },
        dayCellContent(date) {
            // console.log('CalendarSearch, dayCellContent, date, ', date)
            const { priceColor, price } = this.getCalendarInfo(date)
            const isSundayOrSaturday = date.isSunday || date.isSaturday
            const dayColor =
                isSundayOrSaturday && !date.isSelected && !date.isDisabled
                    ? '#f2bc1f'
                    : date.isSelected
                    ? '#ffffff'
                    : date.isToday && this.isHighLightToday && !date.isDisabled
                    ? '#2196f3'
                    : !date.isDisabled
                    ? '#000000'
                    : ''
            const priceElement = this.disablePrices
                ? ``
                : `<div class="position-absolute text-12 w-100" style="color: ${priceColor}; top: 32px;">${price}</div>`
            return `
                <div class="position-relative">
                    <div style="color: ${dayColor};">${date.date}</div>
                    <div class="flex-center">
                        ${priceElement}
                    </div>
                </div>
            `
        },
        getCalendarInfo(date) {
            const formattedDate = this.getDate(date.timestamp)
            const priceInfo = this.calendarPrices
                ? this.calendarPrices[formattedDate]
                : ''
            const priceColor = priceInfo?.color ?? this.textColor
            const price = priceInfo?.value ?? ''
            return { priceColor, price }
        }
    }
}
</script>

<style scoped>
.vdp-datepicker >>> .vdp-datepicker__calendar header span:hover {
    border-radius: 12px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar {
    width: 100% !important;
    border-width: 0;
    background-color: transparent;
}
.vdp-datepicker
    >>> .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid var(--primary) !important;
    border-radius: 50px;
}
.vdp-datepicker
    >>> .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).month:hover {
    border: 1px solid var(--primary) !important;
    border-radius: 50px;
}
.vdp-datepicker
    >>> .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).day:hover {
    border: 1px solid var(--primary) !important;
    border-radius: 50px;
}
.vdp-datepicker
    >>> .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).day:active {
    filter: opacity(0.6);
}
.vdp-datepicker >>> .vdp-datepicker__calendar .cell.selected {
    background: none;
    background-color: var(--primary) !important;
    color: var(--light) !important;
    border-radius: 50px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar .cell.selected:hover {
    background: none;
    background-color: var(--primary) !important;
    color: var(--light) !important;
    border-radius: 50px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar .day {
    height: 42px;
    margin-bottom: 20px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar .day-header:first-child {
    color: var(--primary) !important;
}
.vdp-datepicker >>> .vdp-datepicker__calendar .day-header:nth-child(7) {
    color: var(--primary) !important;
}
</style>
