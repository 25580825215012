<template>
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4"
              d="M1.0293 10.194L4.66947 2.49438L7.70816 4.55004C8.0981 4.81704 8.09734 5.39261 7.70664 5.65848L1.0293 10.194Z"
              fill="#DDA505"/>
        <path opacity="0.2"
              d="M1.21917 0.13474L7.63026 4.44692C8.09129 4.757 8.09129 5.43529 7.63026 5.74537L1.21917 10.0574C0.699447 10.4069 0 10.0345 0 9.40804V0.783873C0 0.15761 0.699447 -0.214793 1.21917 0.13474Z"
              fill="#FFC000"/>
        <path d="M3.5647 8.47971L1.21917 10.0574C0.699447 10.4069 0 10.0345 0 9.40805V6.31638L3.5647 8.47971Z"
              fill="#FFC000"/>
        <path opacity="0.5"
              d="M0 3.16199V0.783872C0 0.15761 0.699447 -0.214793 1.21917 0.13474L7.63026 4.44692C8.09129 4.757 8.09129 5.43529 7.63026 5.74537L5.78502 6.98646L0 3.16199Z"
              fill="#FFC000"/>
        <path opacity="0.5"
              d="M3.18391 1.45626L7.63007 4.44692C8.0911 4.757 8.0911 5.43529 7.63007 5.74537L5.78483 6.98646L0 3.16199V0.783872C0 0.15761 0.699447 -0.214793 1.21917 0.13474L3.18391 1.45626Z"
              fill="#FFC000"/>
    </svg>
</template>

<script>
export default {
    name: 'StyledRightChevronSvg'
}
</script>

<style scoped></style>
