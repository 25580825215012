<template>
    <client-only>
        <div class="d-flex align-items-center">
            <div
                v-if="inputWords.length <= 0"
                :class="
                    isAbsolutePosition
                        ? 'anim-text-container-absolute'
                        : 'anim-text-container-block'
                "
                class="text-797979"
            >
                <p v-if="isFetchingPlaceholders || isPanelOpened">
                    {{ $t('search_bar.next_destination') }}
                </p>
                <div v-else-if="isShowImmediatePlaceholder">
                    <p v-if="paramsRegion === 'my'">
                        {{ currentGroupText }}
                    </p>
                    <p v-else>
                        {{ regionName() }}
                    </p>
                </div>
                <p v-else-if="!isFetchingPlaceholders">
                    <span ref="placeholderRef">
                        {{ currPlaceholder }}
                    </span>
                </p>
            </div>
        </div>
    </client-only>
</template>

<script>
import _filter from 'lodash/filter'

export default {
    name: 'AnimatedText',
    props: {
        inputWords: {
            type: String,
            default: '',
            required: true
        },
        isAbsolutePosition: {
            type: Boolean,
            default: true,
            required: false
        },
        isShowImmediatePlaceholder: {
            type: Boolean,
            default: false,
            required: false
        },
        isPanelOpened: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            currPlaceholder: '',
            animIndex: 0,
            intervals: [],
            textInTimer: 5000,
            textOutTimer: 4300
        }
    },
    computed: {
        animatedPlaceholders() {
            return this.$store.getters['api/animatedPlaceholders']
        },
        isFetchingPlaceholders() {
            return this.$store.getters['api/isFetchingPlaceholders']
        },
        currentGroupText() {
            const selectedRegionObj = this.currentRegionObj
            if (selectedRegionObj && selectedRegionObj.groups?.length > 0) {
                const currentArea = _filter(selectedRegionObj.groups, [
                    'slug',
                    this.paramsGroup
                ])
                return (
                    currentArea?.[0]?.name ?? this.regionName()
                )
            } else {
                return this.regionName()
            }
        }
    },
    watch: {
        animatedPlaceholders() {
            this.currPlaceholder = this.$t('search_bar.next_destination')
            this.animIndex = 0
        }
    },
    mounted() {
        this.initPlaceholderAnimation()
    },
    methods: {
        timeout() {
            return new Promise((resolve) =>
                setTimeout(resolve, this.textInTimer)
            )
        },
        async initPlaceholderAnimation() {
            while (true) {
                // eslint-disable-next-line no-unused-expressions
                this.$refs.placeholderRef?.classList?.remove(
                    'text-in',
                    'text-out'
                )
                // eslint-disable-next-line no-unused-expressions
                this.$refs.placeholderRef?.classList?.add('text-in')
                setTimeout(() => {
                    // eslint-disable-next-line no-unused-expressions
                    this.$refs.placeholderRef?.classList?.add('text-out')
                }, this.textOutTimer)
                // eslint-disable-next-line standard/computed-property-even-spacing
                this.currPlaceholder = this.animatedPlaceholders[
                    this.animIndex
                ]?.query
                this.$emit('updateIndex', this.animIndex)
                await this.timeout()
                this.animIndex++
                if (this.animIndex === this.animatedPlaceholders.length) {
                    this.animIndex = 0
                }
            }
        }
    }
}
</script>

<style scoped>
.anim-text-container-absolute {
    position: absolute;
    z-index: 5;
    left: 48px;
    pointer-events: none;
}
.anim-text-container-block {
    position: static;
    pointer-events: none;
}
@media (max-width: 992px) {
    .anim-text-container {
        left: 40px;
    }
    @keyframes textIn {
        0% {
            transform: translateY(30%);
            opacity: 0;
        }
        100% {
            transform: translateY(0%);
            opacity: 1;
        }
    }
}
@media (min-width: 993px) {
    @keyframes textIn {
        0% {
            transform: translateY(100%);
            opacity: 0;
        }
        100% {
            transform: translateY(0%);
            opacity: 1;
        }
    }
}
.text-in {
    display: block;
    animation: textIn 1.5s ease-out;
}
.text-out {
    animation: textOut 1.5s ease-out;
}
@keyframes textOut {
    0% {
        transform: translateY(0%);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}
</style>
