<template>
    <div class="px-4">
        <ChangeGroupButton class="d-none d-lg-block" />
        <div class="mb-2 d-none d-lg-block">
            <client-only>
                <div class="text-16 text-s-bold">
                    {{ $t('search_bar.search_space') }}
                </div>
                <template #placeholder>
                    <TextSkeleton width="65%" style="height: 24px;" />
                </template>
            </client-only>
        </div>
        <client-only>
            <SpaceTypeList
                :type="type"
                :selected-space-type="selectedSpaceType"
                @setSpaceType="setSpaceType"
                class="mb-4 d-block d-lg-none"
            />
            <LocationSearchPanel
                ref="autocompleteMobileRef"
                v-if="type === 'mobile'"
                :key="updateAutoCompleteKey"
                :input-words="inputValue"
                :type="'mobile'"
                :space-type-name="spaceType.name !== '' ? spaceType.name : selectedSpaceType"
                :is-show-placeholder-suggestion="isShowPlaceholderSuggestion"
                @close="isLocationSearchVisible = false"
                @getCurrentLocation="getCurrentLocation"
                @setInput="setInput"
                @setSuggestionSelected="setSuggestionSelected"
                class="dropdown-field mr-3"
            />
        </client-only>
        <client-only>
            <LocationSearchPanel
                ref="autocompleteDesktopRef"
                v-if="type === 'desktop'"
                :key="updateAutoCompleteKey"
                :input-words="inputValue"
                :type="'desktop'"
                :space-type-name="spaceType.name"
                :is-show-immediate-placeholder="isShowImmediatePlaceholder"
                :is-show-placeholder-suggestion="isShowPlaceholderSuggestion"
                @getCurrentLocation="getCurrentLocation"
                @setInput="setInput"
                @setSuggestionSelected="setSuggestionSelected"
                class="dropdown-field location-field mb-3"
            />
            <template #placeholder>
                <div class="dropdown-field mb-3 px-3">
                    <TextSkeleton width="120px" />
                </div>
            </template>
        </client-only>
        <client-only>
            <b-dropdown
                class="d-none d-lg-flex bg-white dropdown-field mb-3 space-field"
                variant="light"
                toggle-class="text-decoration-none dropdown-field-btn"
                no-caret
                no-flip
            >
                <template #button-content>
                    <div
                        class="d-flex align-items-center justify-content-between px-3 py-2"
                    >
                        <div class="d-flex align-items-center">
                            <ImageContainer
                                :src="spaceType.icon"
                                :alt="spaceType.text"
                                height="20"
                                width="20"
                                class="mr-3 space-icon"
                            />
                            <p class="dropdown-text dropdown-title">
                                {{ spaceType.text }}
                            </p>
                        </div>
                        <p
                            class="text-10 text-ikonoMini-4 text-center dropdown-text dropdown-expand"
                        >
                            
                        </p>
                    </div>
                </template>
                <b-dropdown-item
                    v-for="(item, index) in spaceTypeInfo"
                    :key="index"
                    @click="setSpaceType(item)"
                    class="text-15 text-reg"
                >
                    <div class="d-flex align-items-center space-field-dropdown">
                        <ImageContainer
                            :src="item.icon"
                            :alt="item.space_type_string"
                            height="24"
                            width="24"
                            class="mr-3"
                        />
                        <div>{{ item.space_type_string }}</div>
                    </div>
                    <div
                        v-if="index !== spaceTypeInfo.length - 1"
                        style="border-bottom: 1px solid #e4e4e4; padding: 10px 0;"
                    ></div>
                </b-dropdown-item>
            </b-dropdown>
            <template #placeholder>
                <div class="dropdown-field mb-3 px-3">
                    <TextSkeleton width="60px" />
                </div>
            </template>
        </client-only>
        <client-only>
            <b-dropdown
                :key="isDisablePrice"
                ref="dateDropdownRef"
                @show="openDatepickerSearch"
                class="d-flex bg-white dropdown-field mt-3 date-field"
                variant="light"
                toggle-class="text-decoration-none dropdown-field-btn"
                no-caret
                no-flip
                boundary="window"
            >
                <template #button-content>
                    <div
                        class="d-flex align-items-center justify-content-between px-3 py-2"
                    >
                        <div class="d-flex align-items-center">
                            <CalendarSvg
                                class="mr-3 dropdown-text field-icon"
                            />
                            <p class="dropdown-text dropdown-title">
                                {{ date.value }}
                            </p>
                        </div>
                        <p
                            class="text-10 text-ikonoMini-4 text-center dropdown-text dropdown-expand"
                        >
                            
                        </p>
                    </div>
                </template>
                <b-dropdown-item disabled>
                    <CalendarSearch
                        :selected-date="date.value"
                        :disable-prices="isDisablePrice"
                        @setDate="setDate"
                    />
                </b-dropdown-item>
            </b-dropdown>
            <template #placeholder>
                <div class="dropdown-field mb-3 px-3">
                    <TextSkeleton width="130px" />
                </div>
            </template>
        </client-only>
        <client-only>
            <b-dropdown
                ref="timeDropdownRef"
                @show="openCheckInTimeSearch"
                class="d-flex bg-white dropdown-field mt-3 location-field"
                variant="light"
                toggle-class="text-decoration-none dropdown-field-btn"
                no-caret
                no-flip
                boundary="window"
            >
                <template #button-content>
                    <div
                        class="d-flex align-items-center justify-content-between px-3 py-2"
                    >
                        <div class="d-flex align-items-center">
                            <ScheduleSvg
                                class="mr-3 dropdown-text field-icon"
                                style="height: 18px; width: 18px;"
                            />
                            <div>
                                <p
                                    class="mr-3 dropdown-text dropdown-title text-left multi-line-time"
                                    style="max-width: 150px;"
                                >
                                    {{ computedCheckInTime }}
                                </p>
                            </div>
                        </div>
                        <p
                            class="text-10 text-ikonoMini-4 text-center dropdown-text dropdown-expand"
                        >
                            
                        </p>
                    </div>
                </template>
                <b-dropdown-item disabled class="text-20 text-reg">
                    <CheckInTimeSearch
                        ref="checkInTimeRef"
                        :selected-date="date.value"
                        :selected-service="spaceType.name"
                        @onCheckInTimeClicked="onCheckInTimeClicked"
                        @setCheckInPeriod="setCheckInPeriod"
                        @onConfirmTimeClicked="onConfirmTimeClicked"
                    />
                </b-dropdown-item>
            </b-dropdown>
            <template #placeholder>
                <div class="dropdown-field mb-3 px-3">
                    <TextSkeleton width="100px" />
                </div>
            </template>
        </client-only>
        <div v-if="spaceType.name === 'party'">
            <client-only>
                <b-dropdown
                    class="d-flex bg-white dropdown-field mt-3 num-ppl-field"
                    variant="light"
                    toggle-class="text-decoration-none dropdown-field-btn"
                    no-caret
                    no-flip
                >
                    <template #button-content>
                        <div
                            class="d-flex align-items-center justify-content-between px-3 py-2"
                        >
                            <div class="d-flex align-items-center">
                                <GroupsSvg
                                    class="mr-3 dropdown-text field-icon"
                                />
                                <p class="mr-3 dropdown-text dropdown-title">
                                    {{ num.ppl + $t('ppl') }}
                                </p>
                            </div>
                            <p
                                class="text-10 text-ikonoMini-4 text-center dropdown-text dropdown-expand"
                            >
                                
                            </p>
                        </div>
                    </template>
                    <b-dropdown-item
                        disabled
                        class="text-20 text-reg"
                        style="text-align: center;"
                    >
                        <div
                            v-if="num.ppl > 0"
                            class="d-flex align-items-center justify-content-between"
                        >
                            <p class="text-15 text-s-bold text-3c3c3c mb-0">
                                {{ $t('details.guest') }}
                            </p>
                            <div
                                class="py-3 d-flex align-items-center justify-content-center"
                                style="min-height: 10vh"
                            >
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                >
                                    <IncrementButton
                                        :is-disabled="num.ppl === num.min"
                                        @buttonClicked="setNumPpl(-1)"
                                        >-</IncrementButton
                                    >
                                    <div
                                        class="mx-3 text-18 font-weight-bold text-center"
                                        style="width: 40px"
                                    >
                                        {{ num.ppl }}
                                    </div>
                                    <IncrementButton
                                        :is-disabled="num.ppl === num.max"
                                        @buttonClicked="setNumPpl(1)"
                                        >+</IncrementButton
                                    >
                                </div>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
                <template #placeholder>
                    <div class="dropdown-field mb-3 px-3">
                        <TextSkeleton width="60px" />
                    </div>
                </template>
            </client-only>
        </div>
        <BookButton
            v-if="type === 'desktop'"
            :book-button-text="$t('search_bar.search_space')"
            :is-loading="isLoading || isFetchingLocation"
            :is-disable="isLoading || isFetchingLocation"
            :button-class="'btn-black'"
            @buttonClicked="desktopSearchClick"
            class="mt-3 text-14"
        />
        <client-only>
            <SearchFieldModal
                v-if="isDatePickerVisible"
                @confirmBtnClick="setDate(tempDate)"
                @close="isDatePickerVisible = false"
            >
                <template #searchText>
                    <div
                        class="d-flex align-items-center search-box w-100 px-3"
                    >
                        <CalendarSvg class="mr-3 dropdown-text field-icon" />
                        <p class="dropdown-text dropdown-title">
                            {{ date.value }}
                        </p>
                        <p
                            class="text-10 text-ikonoMini-4 text-center dropdown-text dropdown-expand"
                        >
                            
                        </p>
                    </div>
                </template>
                <template #content>
                    <CalendarSearch
                        :selected-date="date.value"
                        :disable-prices="isDisablePrice"
                        @setDate="setTempDate"
                    />
                </template>
            </SearchFieldModal>
            <SearchFieldModal
                v-if="isCheckInTimeSearchVisible"
                @confirmBtnClick="handleTimeConfirmBtnClick"
                @close="isCheckInTimeSearchVisible = false"
            >
                <template #searchText>
                    <div
                        class="d-flex align-items-center search-box w-100 px-3"
                    >
                        <span class="text-ikonoMini-4 mr-3 dropdown-text">
                            
                        </span>
                        <p class="mr-3 dropdown-text dropdown-title">
                            {{ computedCheckInTime }}
                        </p>
                        <p
                            class="text-10 text-ikonoMini-4 text-center dropdown-text dropdown-expand"
                        >
                            
                        </p>
                    </div>
                </template>
                <template #content>
                    <CheckInTimeSearch
                        :selected-date="date.value"
                        :selected-check-in-time-mobile-view="checkInTime"
                        :selected-service="spaceType.name"
                        @onCheckInTimeClicked="onCheckInTimeClicked"
                        @setCheckInPeriod="setCheckInPeriod"
                        @onConfirmTimeClicked="onConfirmTimeClicked"
                    />
                </template>
            </SearchFieldModal>
        </client-only>
    </div>
</template>

<script>
import IncrementButton from '@/components/item/IncrementButton'
import dateServices from '@/services/dateServices'
import SearchFieldModal from '@/components/modal/SearchFieldModal'
import CheckInTimeSearch from '@/components/ui/CheckInTimeSearch'
import SpaceTypeList from '@/components/item/SpaceTypeList'
import CalendarSearch from '@/components/item/CalendarSearch'
import LocationSearchPanel from '@/components/ui/LocationSearchPanel'
import ChangeGroupButton from '@/components/ui/ChangeGroupButton'
import BookButton from '@/components/item/BookButton'
import GroupsSvg from '@/components/iconSvg/GroupsSvg'
import ScheduleSvg from '@/components/iconSvg/ScheduleSvg'
import TextSkeleton from '@/components/item/TextSkeleton'
import CalendarSvg from '@/components/iconSvg/CalendarSvg'
import { isPeriodTimeFilter } from '@/lib-flow-main-search/src/utils'
import { DATE_TIME } from '@/lib-flow-main-search/src/constants'
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'BasicSearchField',
    components: {
        ImageContainer,
        CalendarSvg,
        TextSkeleton,
        ScheduleSvg,
        GroupsSvg,
        BookButton,
        LocationSearchPanel,
        CalendarSearch,
        SpaceTypeList,
        CheckInTimeSearch,
        SearchFieldModal,
        IncrementButton,
        ChangeGroupButton
    },
    props: {
        option: {
            type: Object,
            default() {
                return {}
            }
        },
        swiperButton: {
            type: Boolean,
            default: false
        },
        swiperPagination: {
            type: Boolean,
            default: false
        },
        numPpl: {
            type: Number,
            default: 0
        },
        minPax: {
            type: Number,
            default: 0
        },
        maxPax: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            required: true,
            default: ''
        },
        headGroups: {
            type: Array,
            default() {
                return []
            }
        },
        setPaxErrorMsg: {
            type: String,
            default: ''
        },
        selectedDate: {
            type: String,
            default: ''
        },
        selectedCheckInTime: {
            type: String,
            required: true,
            default: ''
        },
        inputWords: {
            type: String,
            required: true,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isFetchingLocation: {
            type: Boolean,
            default: false
        },
        selectedSpaceType: {
            type: String,
            default: 'rest'
        },
        isShowImmediatePlaceholder: {
            type: Boolean,
            default: false,
            required: false
        },
        isShowPlaceholderSuggestion: {
            type: Boolean,
            default: true,
            required: false
        },
        inputSpaceType: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            num: { ppl: this.numPpl, min: this.minPax, max: this.maxPax },
            inputValue: this.inputWords,
            spaceType: {
                text: '',
                name: this.selectedSpaceType,
                icon: ''
            },
            date: {
                text: this.selectedDate,
                value: this.selectedDate
            },
            isLocationSearchVisible: false,
            isCheckInTimeSearchVisible: false,
            isDatePickerVisible: false,
            checkInTime: this.selectedCheckInTime,
            tempDate: '',
            tempCheckInTime: '',
            isGetCurrentLocation: false,
            updateAutoCompleteKey: 0,
            tempSelectedCheckInTime: ''
        }
    },
    computed: {
        spaceTypeInfo() {
            return this.$store.getters['api/spaceTypeInfo']
        },
        space() {
            return this.$route.params.space
        },
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        searchBarPlaceholder() {
            return (
                this.$store.getters['api/searchBarPlaceholder'] ??
                this.$t('search_bar.current_location')
            )
        },
        computedCheckInTime() {
            if (this.tempCheckInTime.length > 0) {
                if (
                    this.tempCheckInTime === DATE_TIME.ANY_CHECK_IN_TIME ||
                    this.tempCheckInTime?.[0] === DATE_TIME.ANY_CHECK_IN_TIME
                ) {
                    return this.isHotel
                        ? this.$t('search_bar.any_time')
                        : this.$t('search_bar.any_start_time')
                } else if (
                    this.tempCheckInTime === DATE_TIME.ASAP_CHECK_IN_TIME
                ) {
                    return this.isHotel
                        ? this.$t('search_bar.asap_time')
                        : this.$t('search_bar.asap_time_non_hotel')
                } else if (Array.isArray(this.tempCheckInTime)) {
                    return this.tempCheckInTime
                        .map(time => this.mapToTimePeriodTranslations(time, this.checkInPeriodsTranslations))
                        .join(', ')
                } else if (isPeriodTimeFilter(this.tempCheckInTime)) {
                    return this.tempCheckInTime.split(',')
                        .map(time => this.mapToTimePeriodTranslations(time, this.checkInPeriodsTranslations))
                        .join(', ')
                } else {
                    return this.tempCheckInTime
                }
            }
            if (this.selectedCheckInTime.length > 0) {
                if (
                    this.selectedCheckInTime === DATE_TIME.ANY_CHECK_IN_TIME ||
                    this.selectedCheckInTime?.[0] === DATE_TIME.ANY_CHECK_IN_TIME
                ) {
                    return this.isHotel
                        ? this.$t('search_bar.any_time')
                        : this.$t('search_bar.any_start_time')
                } else if (
                    this.selectedCheckInTime === DATE_TIME.ASAP_CHECK_IN_TIME
                ) {
                    return this.isHotel
                        ? this.$t('search_bar.asap_time')
                        : this.$t('search_bar.asap_time_non_hotel')
                } else if (Array.isArray(this.selectedCheckInTime)) {
                    return this.selectedCheckInTime
                        .map(time => this.mapToTimePeriodTranslations(time, this.checkInPeriodsTranslations))
                        .join(', ')
                } else if (isPeriodTimeFilter(this.selectedCheckInTime)) {
                    return this.selectedCheckInTime.split(',')
                        .map(time => this.mapToTimePeriodTranslations(time, this.checkInPeriodsTranslations))
                        .join(', ')
                } else {
                    return this.selectedCheckInTime
                }
            }
            return dateServices.getAsapMinuteInterval(this.region)
        },
        isHotel() {
            return this.spaceType.name === 'rest' || this.paramsSpace === 'hotel'
        },
        isDisablePrice() {
            return (
                this.inputValue !== '' && this.inputValue !== this.regionName()
            )
        },
        checkInPeriodsTranslations() {
            const translations = {}
            this.$store.getters['api/checkInPeriods'].forEach(period => {
                translations[period.value] = period.text
            })
            return translations
        }
    },
    watch: {
        inputWords(newVal, oldVal) {
            if (
                newVal === this.$t('search_bar.current_location') &&
                newVal !== oldVal
            ) {
                // eslint-disable-next-line no-unused-expressions
                this.$refs?.autocompleteDesktopRef?.refreshAutoComplete(
                    this.services,
                    this.$t('search_bar.current_location')
                )
                // eslint-disable-next-line no-unused-expressions
                this.$refs?.autocompleteMobileRef?.refreshAutoComplete(
                    this.services,
                    this.$t('search_bar.current_location')
                )
            }
        }
    },
    mounted() {
        if (this.type === 'desktop') this.setDefaultSpaceType()
        if (this.isAirportHotelsPage) this.isDatePickerVisible = true
    },
    methods: {
        setDefaultSpaceType() {
            const spaceType = {
                space_type_string: this.inputSpaceType.text,
                space_type: this.inputSpaceType.name,
                icon: this.inputSpaceType.icon
            }
            this.setSpaceType(spaceType)
        },
        setSpaceType(spaceType) {
            this.spaceType.text = spaceType.space_type_string
            this.spaceType.name = spaceType.space_type
            this.spaceType.icon = spaceType.icon
            if (this.type === 'mobile') {
                this.$emit('setSpaceType', this.spaceType.name)
            }
            this.updateAutoCompleteKey += 1
        },
        setNumPpl(sign) {
            this.num.ppl = this.num.ppl + sign
            this.$emit('setNumPpl', this.num.ppl)
        },
        setDate(date) {
            if (!date) date = this.selectedDate
            // Picker Date changed
            if (date !== this.date.value) {
                if (this.type === 'mobile') {
                    this.setTempCheckInTime('any_time')
                    this.setCheckInTime('any_time')
                } else {
                    this.setCheckInTime('')
                }
            }
            this.date.value = date
            this.date.text = date
            this.$emit('setDate', this.date.value)
            this.$refs.dateDropdownRef.hide()
        },
        setTempDate(date) {
            this.tempDate = date
        },
        setTempCheckInTime(checkInTime) {
            this.tempCheckInTime = checkInTime
        },
        openCheckInTimeSearch(bvEvent) {
            this.$refs.checkInTimeRef.calculateOtherCheckInTime()
            if (this.type === 'mobile') {
                this.isCheckInTimeSearchVisible = true
                bvEvent.preventDefault()
            }
        },
        openDatepickerSearch(bvEvent) {
            if (this.type === 'mobile') {
                this.isDatePickerVisible = true
                bvEvent.preventDefault()
            }
        },
        setCheckInTime(checkInTime, autoClose = true) {
            this.checkInTime = checkInTime
            this.$emit('setCheckInTime', this.checkInTime)
            if (autoClose) {
                // eslint-disable-next-line no-unused-expressions
                this.$refs?.timeDropdownRef?.hide()
            }
        },
        setInput(item, forceResetLocation) {
            this.setQuery(item)
            if (forceResetLocation) {
                this.isGetCurrentLocation = false
            }
        },
        setSuggestionSelected(
            query,
            category,
            locationHierarchies,
            suggestionId,
            isQuerySuggestion
        ) {
            this.setQuery(query)
            this.$emit('setSuggestionSelected', query)
            this.$emit(
                'setQuerySourceFromBasicSearchField',
                query,
                category,
                locationHierarchies,
                suggestionId,
                isQuerySuggestion
            )
        },
        // get location directly once user click SearchNearby button
        getCurrentLocation() {
            this.$emit('getCurrentLocation')
            this.isGetCurrentLocation = true
            this.setQuery(this.$t('search_bar.current_location'), true)
        },
        setQuery(query) {
            this.inputValue = query
            if (this.type === 'mobile')
                this.$emit(
                    'setQuery',
                    query,
                    query === this.$t('search_bar.current_location')
                )
        },
        desktopSearchClick() {
            // if (this.isGetCurrentLocation) {
            //     this.$emit('setQuery', this.searchBarPlaceholder, true)
            // } else {
            //     this.$emit('setQuery', this.inputValue)
            // }
            if (!this.isGetCurrentLocation) {
                this.$emit('setQuery', this.inputValue)
            }
            this.$emit('setSpaceType', this.spaceType.name)
            this.$emit('setNumPpl', this.num.ppl)
            this.$emit('setDate', this.date.value)
            this.$emit('desktopSearchClick')
        },
        onCheckInTimeClicked(checkInTime) {
            if (this.type === 'mobile') {
                this.tempSelectedCheckInTime = checkInTime
            } else {
                this.setCheckInTime(checkInTime)
            }
        },
        handleTimeConfirmBtnClick() {
            this.setTempCheckInTime(this.tempSelectedCheckInTime)
            this.setCheckInTime(this.tempSelectedCheckInTime)
        },
        setCheckInPeriod(selectedPeriods) {
            if (this.type === 'mobile') {
                this.tempSelectedCheckInTime = selectedPeriods
            } else {
                this.setCheckInTime(selectedPeriods, false)
            }
            if (selectedPeriods?.[0] === DATE_TIME.ANY_CHECK_IN_TIME) {
                this.$refs?.timeDropdownRef?.hide()
            }
        },
        onConfirmTimeClicked() {
            this.$refs?.timeDropdownRef?.hide()
        }
    }
}
</script>

<style scoped>
.dropdown-field {
    border: 1px solid #d6d6d6;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background-color: white;
}
.dropdown-field >>> .dropdown-field-btn {
    background-color: transparent !important;
    border: transparent;
    padding: 0;
}
.dropdown-title {
    font-size: 14px;
    font-weight: 400;
}
.show >>> .dropdown-text {
    color: #f2bc1f !important;
    fill: #f2bc1f !important;
    border-color: #f2bc1f !important;
}
.space-icon {
    filter: invert(85%) sepia(9%) saturate(177%) hue-rotate(11deg)
        brightness(84%) contrast(89%);
}
.field-icon {
    fill: #b5b3ab;
    color: #b5b3ab;
    width: 18px;
    height: 18px;
    font-size: 18px;
}
.show >>> .space-icon {
    filter: invert(74%) sepia(74%) saturate(481%) hue-rotate(352deg)
        brightness(88%) contrast(106%);
}
.show {
    color: #f2bc1f !important;
    border-color: #f2bc1f !important;
}

.dropdown-field >>> .dropdown-menu {
    margin-top: 10px;
    border-radius: 12px;
    border: none;
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%),
        0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%) !important;
}
.dropdown-item.disabled {
    pointer-events: auto;
}
.space-field >>> .dropdown-item {
    cursor: pointer !important;
    padding: 10px 12px;
}
.space-field >>> .dropdown-item:active {
    background-color: transparent;
    color: #f2bc1f;
}
.space-field >>> .dropdown-item:hover {
    background-color: transparent;
}
.space-field >>> .space-field-dropdown:hover {
    filter: invert(74%) sepia(74%) saturate(481%) hue-rotate(352deg)
        brightness(88%) contrast(106%);
}
.location-field >>> .dropdown-item,
.num-ppl-field >>> .dropdown-item,
.date-field >>> .dropdown-item {
    white-space: normal !important;
    pointer-events: auto !important;
    color: black;
    cursor: auto !important;
}
@media (min-width: 768px) and (max-width: 767.98px) {
    .date-picker-wrapper >>> .vdp-datepicker__calendar {
        font-size: 14px !important;
        width: 100%;
        height: 100%;
    }
}
@media (max-width: 575.98px) {
    .dropdown-field >>> .dropdown-menu {
        width: 100%;
    }
}
@media (min-width: 576px) {
    .space-field >>> .dropdown-menu {
        width: 235px;
        padding: 10px;
    }
    .num-ppl-field >>> .dropdown-menu {
        width: 331px;
    }
    .location-field >>> .dropdown-menu {
        width: 400px;
        max-height: 370px;
        overflow: auto;
    }
}
.dropdown-expand {
    color: transparent;
}
.search-box {
    border-radius: 8px;
    border-style: solid;
    border-width: thin;
    border-color: black;
    color: black !important;
    height: 45px;
}
.multi-line-time {
    white-space: initial;
    -webkit-line-clamp: 2;
    line-height: 15px;
    min-height: 30px;
    margin: 0;
    display: table-cell;
    vertical-align: middle;
}
</style>
