<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="18px"
        viewBox="0 0 24 24"
        width="18px"
        :fill="fillColor"
    >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    </svg>
</template>

<script>
export default {
    name: 'ChevronRightSvg',
    props: {
        fillColor: {
            type: String,
            required: false,
            default: '#000000'
        }
    }
}
</script>
