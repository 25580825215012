<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div @click.stop class="d-flex justify-content-center">
                        <div class="bg-light content">
                            <div
                                style="min-width: 250px; overflow: auto; max-width: 650px; height: var(--vh);"
                                class="content-container py-sm-4 px-4 py-4 d-flex flex-column"
                            >
                                <div
                                    class="d-flex align-items-center justify-content-between mb-4 flex-row-reverse"
                                >
                                    <slot name="searchText" />
                                    <TextButton
                                        v-if="isDisplayCancelBtn"
                                        @handleBtnClick="close"
                                        class="text-dark mr-3"
                                        style="text-decoration-line: none;"
                                        >{{ $t('cancel') }}</TextButton
                                    >
                                </div>
                                <div
                                    class="h-100 overflow-auto d-flex align-items-center flex-column"
                                >
                                    <slot name="content" />
                                </div>
                                <BookButton
                                    :book-button-text="
                                        $t('search_bar.confirm_input')
                                    "
                                    :button-class="'btn-white'"
                                    @buttonClicked="bookButtonClick"
                                    class="text-14 p-3 mt-4 button-active"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import TextButton from '@/components/item/TextButton'
import BookButton from '@/components/item/BookButton'
export default {
    name: 'SearchFieldModal',
    components: { BookButton, TextButton },
    props: {
        inputText: {
            type: String,
            default: ''
        },
        isDisplayCancelBtn: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {}
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        searchBarSetting() {
            return this.$store.getters['api/searchBarSetting']
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        setSpaceType(spaceType) {
            this.$emit('setSpaceType', spaceType)
        },
        setNumPpl(numPpl) {
            this.$emit('setNumPpl', numPpl)
        },
        setDate(date) {
            this.$emit('setDate', date)
        },
        bookButtonClick() {
            this.close()
            this.$emit('confirmBtnClick')
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-container {
    transition: all 300ms;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
}
.full-screen {
    height: 100vh;
    width: 100vw;
}
.content {
    position: relative;
    border-radius: 10px;
}
.close-btn {
    font-size: 22px;
    position: absolute;
    right: -50px;
    top: 0;
    color: #ffffff;
    transform: rotate(45deg);
}
.slide-fade-enter-active .content-container {
}
.slide-fade-leave-active .content-container {
}
.slide-fade-enter, .slide-fade-leave-to .content-container
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.slide-fade-enter-active .content-container {
}
.slide-fade-leave-active .content-container {
}
.slide-fade-enter, .slide-fade-leave-to .content-container
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
@media (max-width: 575.98px) {
    .content {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
    }
    .close-btn {
        font-size: 22px;
        position: unset;
        right: 0;
        top: 0;
        color: #505050;
        transform: rotate(45deg);
    }
}
.search-box {
    border-radius: 8px;
    border-style: solid;
    border-width: thin;
    border-color: black;
    color: black !important;
    height: 45px;
}
</style>
