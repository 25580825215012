<template>
    <b-skeleton-wrapper :loading="true" class="d-flex align-items-end">
        <template #loading>
            <b-skeleton :width="width" :height="height"></b-skeleton>
        </template>
    </b-skeleton-wrapper>
</template>
<script>
export default {
    name: 'TextSkeleton',
    props: {
        height: {
            type: String,
            default: '15px'
        },
        width: {
            type: String,
            default: '100px'
        }
    }
}
</script>
