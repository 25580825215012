<template>
    <div>
        <!-- HIDE BUTTON UNTIL BE FAV LIST API READY-->
        <b-button
            v-if="false"
            :key="updateLike"
            @click.stop.prevent="setLike(slug)"
            aria-label="click like"
            class="d-flex align-items-center bg-transparent border-0 px-3 position-relative"
        >
            <FavHeartBg height="32px" width="32px" />
            <FavHeart
                :is-filled="isLiked(slug)"
                height="24px"
                width="24px"
                class="position-absolute space-fav-icon"
            />
        </b-button>
    </div>
</template>
<script>
import _find from 'lodash/find'
import storage from '@/services/storage'
import analysis from '@/services/analysis'
import FavHeart from '@/components/iconSvg/FavHeart'
import FavHeartBg from '@/components/iconSvg/FavHeartBg'

export default {
    name: 'SpaceCardFavButton',
    components: {
        FavHeart,
        FavHeartBg
    },
    props: {
        selected: {
            type: Object,
            default() {
                return {}
            }
        },
        slug: {
            type: String,
            required: true
        },
        spaceName: {
            type: String,
            required: true
        },
        space: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: 'red'
        }
    },
    data() {
        return {
            updateLike: 0
        }
    },
    methods: {
        setLike(slug) {
            storage.setLiked(slug)
            analysis.sendAddToWishList({
                name: this.spaceName,
                slug,
                space: this.space
            })
            this.updateLike += 1
        },
        isLiked(id) {
            if (storage.get(storage.key.liked)) {
                const list = JSON.parse(storage.get(storage.key.liked)).list
                return !!_find(list, (i) => {
                    return i === id
                })
            } else {
                return false
            }
        }
    }
}
</script>
<style scoped>
.space-fav-icon {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
}
</style>
