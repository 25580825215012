<template>
    <div
        v-if="groupList.length > 0"
        class="position-absolute change-group-container align-items-end"
    >
        <b-button
            @click="onChangeRegionClicked"
            variant="transparent"
        >
            <p class="text-14 text-mid text-606060 text-right">
                {{ $t('header.current_area') }}
            </p>
            <div class="d-flex align-items-center justify-content-end">
                <StyledRightChevronSvg />
                <p
                    class="text-14 text-bold text-ffc000 ml-1"
                    style="text-decoration: underline;"
                >
                    {{ currentGroupText }}
                </p>
            </div>
        </b-button>
    </div>
</template>

<script>
import StyledRightChevronSvg from '@/components/iconSvg/StyledRightChevronSvg'
import _filter from 'lodash/filter'
import { MODAL_MODE } from '@/store/general'

export default {
    name: 'MobileChangeGroupButton',
    components: {
        StyledRightChevronSvg
    },
    computed: {
        groupList() {
            const region = this.paramsRegion
            const currentRegion = _filter(this.regionList, [
                'country_code',
                region
            ])
            if (currentRegion.length <= 0) {
                return []
            }
            return currentRegion[0].groups
        },
        currentGroupText() {
            const selectedRegionObj = this.currentRegionObj
            if (selectedRegionObj && selectedRegionObj.groups?.length > 0) {
                const currentArea = _filter(selectedRegionObj.groups, [
                    'slug',
                    this.paramsGroup
                ])
                return (
                    currentArea?.[0]?.name ??
                    this.$t('header.current_area_placeholder')
                )
            } else {
                return this.$t('header.current_area_placeholder')
            }
        }
    },
    methods: {
        onChangeRegionClicked() {
            this.$store.dispatch('general/SET_LOCALE_MODAL_VISIBLE', {
                isVisible: true,
                mode: MODAL_MODE.GROUP
            })
        }
    }
}
</script>

<style scoped>
.change-group-container {
    right: 0;
    top: 0;
}
</style>
