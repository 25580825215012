<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-container">
                <div @click.stop class="d-flex justify-content-center">
                    <div
                        class="bg-light content d-flex flex-column"
                        style="height: var(--vh)"
                    >
                        <div
                            class="d-flex align-items-center justify-content-center py-4 px-3 position-relative"
                        >
                            <b-button
                                @click="close"
                                class="close-btn bg-transparent border-0 text-292929 text-ikonoMini-4"
                            >
                                
                            </b-button>
                        </div>
                        <MobileChangeGroupButton class="d-block d-lg-none" />
                        <div class="py-sm-4 overflow-y-auto h-100">
                            <BasicSearchField
                                :type="'mobile'"
                                :num-ppl="numPpl"
                                :min-pax="minPax"
                                :max-pax="maxPax"
                                :selected-date="selectedDate"
                                :input-words="inputWords"
                                :selected-check-in-time="inputCheckInTime"
                                :selected-space-type="selectedSpaceType"
                                :is-show-placeholder-suggestion="
                                    isShowPlaceholderSuggestion
                                "
                                @setNumPpl="setNumPpl"
                                @setSpaceType="selectSpaceType"
                                @setDate="setDate"
                                @setCheckInTime="setCheckInTime"
                                @getCurrentLocation="getCurrentLocation"
                                @setQuery="setQuery"
                                @setQuerySourceFromBasicSearchField="
                                    setQuerySourceFromBasicSearchField
                                "
                            />
                            <div
                                v-if="isLoading.searchBarSetting"
                                class="flex-center py-4"
                            >
                                <b-spinner variant="primary" />
                            </div>
                            <AdvanceSearch
                                v-else
                                :input-theme="inputTheme"
                                :input-price-range="inputPriceRange"
                                :input-time-frame="inputTimeFrame"
                                :input-star-rating="inputStarRating"
                                :is-show-theme="isShowTheme"
                                :is-show-time-frame="isShowTimeFrame"
                                @setPriceRange="setPriceRange"
                                @setTimeFrame="setTimeFrame"
                                @setStarRating="setStarRating"
                                @setTheme="setTheme"
                                @setClearFilter="setClearFilter"
                                class="mb-3"
                            />
                        </div>
                        <GradientOpacity />
                        <div class="py-3 px-4">
                            <BookButton
                                :book-button-text="
                                    $t('search_bar.search_space')
                                "
                                :is-loading="isLoadingButton"
                                :is-disable="isLoadingButton"
                                :button-class="'btn-black'"
                                @buttonClicked="searchButtonClick"
                                class="text-14"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import BasicSearchField from '@/components/ui/BasicSearchField'
import AdvanceSearch from '@/components/ui/AdvanceSearch'
import BookButton from '@/components/item/BookButton'
import GradientOpacity from '@/components/item/GradientOpacity'
import MobileChangeGroupButton from '@/components/ui/MobileChangeGroupButton'

export default {
    name: 'Search',
    components: {
        GradientOpacity,
        BookButton,
        AdvanceSearch,
        BasicSearchField,
        MobileChangeGroupButton
    },
    props: {
        numPpl: {
            type: Number,
            default: 0
        },
        minPax: {
            type: Number,
            default: 0
        },
        maxPax: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            required: true,
            default: ''
        },
        headGroups: {
            type: Array,
            default() {
                return []
            }
        },
        setPaxErrorMsg: {
            type: String,
            default: ''
        },
        selectedDate: {
            type: String,
            default: '',
            required: true
        },
        inputWords: {
            type: String,
            default: ''
        },
        inputPriceRange: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        inputTimeFrame: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        inputCheckInTime: {
            type: String,
            required: true,
            default: ''
        },
        inputStarRating: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        inputTheme: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        isFetchingLocation: {
            type: Boolean,
            default: false
        },
        isShowTheme: {
            type: Boolean,
            default: false
        },
        isShowTimeFrame: {
            type: Boolean,
            default: false
        },
        selectedSpaceType: {
            type: String,
            default: ''
        },
        isShowPlaceholderSuggestion: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data() {
        return {
            selected: {
                spaceType: '',
                numPpl: '',
                date: '',
                words: '',
                checkInTime: '',
                selectedSuggestion: ''
            },
            isLoading: {
                searchBarSetting: false
            },
            isSpaceTypeSelected: false
        }
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        isLoadingButton() {
            return this.isFetchingLocation || this.isLoading.searchBarSetting
        }
    },
    watch: {},
    destroyed() {
        this.addScrollBar()
    },
    created() {
        this.hiddenScrollBar()
    },
    async mounted() {
        if (this.selectedSpaceType === '') {
            await this.setSearchBarSetting('rest')
            this.setSpaceType('rest')
        }
    },
    methods: {
        close() {
            if (this.isSpaceTypeSelected) {
                this.$emit('resetSearchBarSetting')
            }
            this.$emit('close')
        },
        selectSpaceType(spaceType) {
            this.isSpaceTypeSelected = true
            this.setSearchBarSetting(spaceType)
            this.setSpaceType(spaceType)
        },
        async setSearchBarSetting(spaceType) {
            this.isLoading.searchBarSetting = true
            await this.getSearchBarSettingWithSpaceType(spaceType)
            this.$emit('setPriceRangeFromSearchBarSetting')
            this.isLoading.searchBarSetting = false
        },
        setSpaceType(spaceType) {
            this.selected.spaceType = spaceType
        },
        setNumPpl(numPpl) {
            this.selected.numPpl = numPpl
        },
        setDate(date) {
            this.selected.date = date
        },
        getCurrentLocation(isGetCurrentLocation = true) {
            this.selected.getCurrentLocation = isGetCurrentLocation
            this.$emit('getCurrentLocation')
        },
        setPriceRange(priceRange) {
            this.selected.priceRange = priceRange
        },
        setTimeFrame(timeFrame) {
            this.selected.timeFrame = timeFrame
        },
        setStarRating(starRating) {
            this.selected.starRating = starRating
        },
        setTheme(theme) {
            this.selected.theme = theme
        },
        setClearFilter() {
            // this.$emit('setClearFilter')
        },
        searchButtonClick() {
            this.$emit('searchButtonClick', this.selected)
            this.close()
        },
        setCheckInTime(checkInTime) {
            this.selected.checkInTime = checkInTime
        },
        setQuery(query, getCurrentLocation) {
            this.selected.getCurrentLocation = getCurrentLocation
            this.selected.words = query
        },
        setQuerySourceFromBasicSearchField(
            value,
            category,
            locationHierarchies,
            suggestionId,
            isQuerySuggestion
        ) {
            this.$emit(
                'setQuerySourceFromSearch',
                value,
                category,
                locationHierarchies,
                suggestionId,
                isQuerySuggestion
            )
        },
        getSearchBarSettingWithSpaceType(spaceType) {
            return this.$store.dispatch('api/GET_SEARCH_BAR_SETTING', {
                space_type: spaceType,
                region: this.region,
                lang: this.lang,
                group: this.paramsGroup,
                device: this.deviceType()
            })
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* fix mobile safari items alignment bug*/
    display: flex;
    align-items: flex-end;
    transition: opacity 0.3s ease;
}
.modal-container {
    transition: all 300ms;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
}
.full-screen {
    height: 100vh;
    width: 100vw;
}
.content {
    width: 100vw;
    border-radius: 15px 15px 0 0;
}
.close-btn {
    font-size: 18px;
    position: absolute;
    left: 9px;
    top: 13px;
    color: #505050;
    transform: rotate(45deg);
}
.slide-fade-enter-active .content-container {
}
.slide-fade-leave-active .content-container {
}
.slide-fade-enter, .slide-fade-leave-to .content-container
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.slide-fade-enter-active .content-container {
}
.slide-fade-leave-active .content-container {
}
.slide-fade-enter, .slide-fade-leave-to .content-container
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
</style>
