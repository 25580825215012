<template>
    <div class="px-4 py-3">
        <div
            v-for="(filters, index) in searchBarSetting.filtering"
            v-if="searchBarSetting"
            :key="`filter_${index}`"
        >
            <div
                v-if="filters.key === 'f_theme' && isShowTheme"
                class="mb-1"
            >
                <div class="text-14 text-s-bold d-flex mb-1">
                    {{ filters.name }}
                </div>
                <b-form-checkbox
                    v-for="(item, themeIndex) in filters.value"
                    :key="`theme_${themeIndex}`"
                    v-model="input.selectedTheme"
                    :value="item.value"
                    :class="
                        isThemeSelected(item)
                            ? 'theme-checkbox-selected'
                            : 'theme-checkbox'
                    "
                    @change="setTheme"
                    class="mr-3 mb-2 px-2 py-1 text-14 button-active"
                    size="sm"
                    button-variant="transparent"
                    button
                >
                    {{ item.text }}
                </b-form-checkbox>
            </div>
            <div v-else-if="filters.key === 'f_priceRange'" class="mb-1">
                <client-only>
                    <div class="d-flex justify-content-between">
                        <p class="text-14 text-s-bold">
                            {{ filters.name }}
                        </p>
                        <div class="d-flex">
                            <p
                                class="text-14 text-mid align-self-center text-center"
                            >
                                {{ priceFilterValue(filters.value) }}
                            </p>
                        </div>
                    </div>
                    <template #placeholder>
                        <div
                            class="d-flex justify-content-between"
                            style="height: 21px;"
                        >
                            <TextSkeleton width="80px" />
                            <TextSkeleton width="70px" />
                        </div>
                    </template>
                </client-only>
                <div
                    class="d-flex align-items-center py-1"
                    style="height: 40px;"
                >
                    <client-only>
                        <div class="w-100 px-2">
                            <vue-slider
                                v-model="input.selectedPriceRange"
                                :min="filters.value.min"
                                :max="filters.value.max"
                                :enable-cross="true"
                                :clickable="false"
                                :style="
                                    draggingPrice
                                        ? 'z-index: 9998;'
                                        : 'z-index: 0;'
                                "
                                aria-label="Choose a price range"
                                @drag-start="draggingPrice = true"
                                @drag-end="setPriceRange"
                                class="align-self-center flex-fill"
                            />
                        </div>
                        <template #placeholder>
                            <TextSkeleton width="200px" style="height: 18px;" />
                        </template>
                    </client-only>
                </div>
            </div>
            <div v-else-if="filters.key === 'f_star_rating'">
                <div class="mb-2">
                    <client-only>
                        <p class="text-14 text-s-bold">
                            {{ filters.name }}
                        </p>
                        <template #placeholder>
                            <TextSkeleton width="80px" style="height: 21px;" />
                        </template>
                    </client-only>
                </div>
                <client-only>
                    <b-form-checkbox
                        :id="`star-checkbox-${starIndex + 1}`"
                        v-for="(star, starIndex) in getStarRangeRange(
                            filters.value.min,
                            filters.value.max
                        )"
                        :key="`star_${starIndex}`"
                        v-model="input.selectedStarRating"
                        :value="star"
                        :class="
                            isStarRatingSelected(star)
                                ? 'other-checkbox-selected'
                                : 'other-checkbox'
                        "
                        :aria-label="`star-checkbox-${starIndex + 1}`"
                        @change="setStarRating"
                        class="mr-3 mb-2 text-14 button-active"
                        size="sm"
                        button-variant="transparent"
                        button
                    >
                        <div class="d-flex">
                            <StarSvg
                                v-for="(btn, i) in new Array(
                                    Math.round(star)
                                ).fill(0)"
                                :key="i"
                                style="height: 18px; width: 18px;"
                            />
                        </div>
                    </b-form-checkbox>
                    <template #placeholder>
                        <div style="height: 72px;">
                            <div class="d-flex flex-wrap">
                                <TextSkeleton width="70px" class="mr-3 mb-1" />
                                <TextSkeleton width="90px" class="mr-3 mb-1" />
                                <TextSkeleton width="100px" />
                            </div>
                        </div>
                    </template>
                </client-only>
            </div>
            <!--      Disable book type filter      -->
            <div v-else-if="filters.key === 'f_book_type' && isShowTimeFrame && filters.value?.length > 0">
                <p class="text-14 text-s-bold mb-1">
                    {{ filters.name }}
                </p>
                <b-form-checkbox
                    :id="`time-frame-${timeFrameIndex + 1}`"
                    v-for="(item, timeFrameIndex) in filters.value"
                    :key="timeFrameIndex"
                    v-model="input.selectedTimeFrame"
                    :value="item.value"
                    :class="
                        isTimeFrameSelected(item)
                            ? 'theme-checkbox-selected'
                            : 'theme-checkbox'
                    "
                    :aria-label="`time-frame-${timeFrameIndex + 1}`"
                    @change="setTimeFrame"
                    class="mr-3 mb-2 px-2 py-1 button-active"
                    size="sm"
                    button-variant="transparent"
                    button
                >
                    <div class="text-14">
                        {{ item.text }}
                    </div>
                </b-form-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
import _range from 'lodash/range'
import TextSkeleton from '~/components/item/TextSkeleton.vue'
import StarSvg from '~/components/iconSvg/StarSvg.vue'

export default {
    name: 'AdvanceSearch',
    components: { StarSvg, TextSkeleton },
    props: {
        inputTheme: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        inputPriceRange: {
            type: Array,
            default() {
                return []
            }
        },
        inputTimeFrame: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        inputStarRating: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        isShowTheme: {
            type: Boolean,
            default: false
        },
        isShowTimeFrame: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            input: {
                selectedCheckInTime: '',
                spaceType: {},
                words: '',
                date: {},
                selectedTheme: this.inputTheme,
                selectedPriceRange: this.inputPriceRange,
                selectedTimeFrame: this.inputTimeFrame,
                selectedStarRating: this.inputStarRating,
                page: 1,
                sort: {
                    name: 'list.s_featured',
                    key: '',
                    value: ''
                }
            },
            filter: {
                visible: false,
                keys: {
                    showPopup: false
                }
            },
            draggingPrice: false
        }
    },
    computed: {
        searchBarSetting() {
            return this.$store.getters['api/searchBarSetting']
        }
    },
    methods: {
        getStarRangeRange(min, max) {
            return _range(min, max + 1)
        },
        setTheme(checked) {
            this.$emit('setTheme', checked)
        },
        isThemeSelected(item) {
            return this.input.selectedTheme.includes(item.value)
        },
        setPriceRange() {
            this.draggingPrice = false
            this.$emit('setPriceRange', this.input.selectedPriceRange)
        },
        isTimeFrameSelected(item) {
            return this.input.selectedTimeFrame.includes(item.value)
        },
        setTimeFrame(checked) {
            this.$emit('setTimeFrame', checked)
        },
        isStarRatingSelected(item) {
            return this.input.selectedStarRating.includes(item)
        },
        setStarRating(checked) {
            this.$emit('setStarRating', checked)
        },
        priceFilterValue(filterValue) {
            if (
                (filterValue.min === this.input.selectedPriceRange[0] &&
                    filterValue.max === this.input.selectedPriceRange[1]) ||
                (filterValue.min !== this.input.selectedPriceRange[0] &&
                    filterValue.max === this.input.selectedPriceRange[1])
            ) {
                return `${this.input.selectedPriceRange[0]} - ${this.input.selectedPriceRange[1]}+`
            } else {
                return `${this.input.selectedPriceRange[0]} - ${this.input.selectedPriceRange[1]}`
            }
        }
    }
}
</script>

<style scoped>
.theme-checkbox-selected {
    border-radius: 4px;
    background: #f2bc1f;
    color: white;
    font-weight: 500;
}
.theme-checkbox {
    border-radius: 4px;
    background: #f3f2f0;
    white-space: nowrap;
    color: #31302c;
    font-weight: 500;
}
.other-checkbox-selected {
    border-radius: 4px;
    font-weight: 500;
    border: 1px solid #f2bc1f;
}
.other-checkbox {
    border-radius: 4px;
    white-space: nowrap;
    border: 1px solid #c8c8c8;
}
.other-checkbox-selected >>> svg {
    fill: #f2bc1f;
}
.other-checkbox >>> svg {
    fill: #c8c8c8;
}
</style>
