<template>
    <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_320_1324)">
            <path
                d="M8.22413 0.000497067C9.60413 0.000497067 10.9841 0.000497067 12.3716 0.000497067C13.9466 0.000497067 15.2741 1.09535 15.6416 2.66823C15.7016 2.93037 15.7241 3.19252 15.7241 3.46238C15.7241 5.11236 15.7241 6.75463 15.7241 8.40461C15.7241 10.1857 14.4941 11.6121 12.7766 11.8434C12.6191 11.8665 12.4541 11.8665 12.2966 11.8665C11.6966 11.8665 11.0891 11.8665 10.4891 11.8665C10.3166 11.8665 10.1966 11.9282 10.0916 12.067C9.60413 12.73 9.11663 13.3854 8.62913 14.0485C8.36663 14.4031 8.08163 14.4031 7.82663 14.0485C7.34663 13.4008 6.85913 12.7455 6.38663 12.0901C6.26663 11.9282 6.13163 11.8588 5.93663 11.8588C5.29163 11.8588 4.64663 11.8742 3.99413 11.8588C2.17913 11.8279 0.739131 10.3245 0.724131 8.45858C0.716631 6.78547 0.716631 5.10465 0.724131 3.43154C0.739131 1.52712 2.21663 0.0159175 4.06913 0.000497067C5.45663 -0.00721313 6.84413 0.000497067 8.23163 0.000497067H8.22413ZM8.23163 0.871749C8.10413 0.871749 7.97663 0.871749 7.85663 0.871749C7.09913 0.871749 6.34163 0.871749 5.58413 0.871749C4.92413 0.871749 4.20413 0.771517 3.55163 0.925721C2.36663 1.19558 1.57913 2.22103 1.57913 3.50093C1.57913 5.12007 1.57913 6.73921 1.57913 8.35835C1.57913 8.52027 1.58663 8.68989 1.61663 8.8518C1.84913 10.1317 2.84663 10.9875 4.11413 10.9952C4.73663 10.9952 5.36663 10.9952 5.98913 10.9952C6.43163 10.9952 6.77663 11.1649 7.03913 11.535C7.39163 12.0207 7.75163 12.5064 8.11163 12.9845C8.19163 13.0924 8.27163 13.0924 8.35163 12.9845C8.71163 12.4987 9.07163 12.0207 9.42413 11.535C9.68663 11.1726 10.0241 11.0029 10.4591 11.0029C11.0741 11.0029 11.6891 11.0029 12.2966 11.0029C12.4766 11.0029 12.6491 10.9952 12.8216 10.9567C14.0591 10.71 14.8691 9.68451 14.8691 8.35835C14.8691 6.74692 14.8691 5.12778 14.8691 3.51635C14.8691 3.29275 14.8541 3.06145 14.8016 2.83785C14.5241 1.68132 13.5566 0.88717 12.3941 0.87946C11.0066 0.871749 9.61913 0.871749 8.23163 0.87946V0.871749Z"
                fill="#FDB93E"
            />
            <path
                d="M8.23168 5.03505C8.71918 5.03505 9.11668 5.44369 9.11668 5.94486C9.11668 6.43831 8.71168 6.84695 8.23918 6.84695C7.75168 6.84695 7.35418 6.43831 7.34668 5.93715C7.34668 5.43598 7.73668 5.03505 8.23168 5.02734V5.03505Z"
                fill="#FDB93E"
            />
            <path
                d="M11.1791 5.03429C11.6591 5.03429 12.0491 5.43522 12.0491 5.93638C12.0491 6.43754 11.6516 6.84619 11.1641 6.84619C10.6766 6.84619 10.2791 6.42983 10.2866 5.92867C10.2866 5.42751 10.6916 5.02658 11.1866 5.03429H11.1791Z"
                fill="#FDB93E"
            />
            <path
                d="M4.3991 5.93725C4.3991 5.42838 4.7891 5.03516 5.2841 5.03516C5.7716 5.03516 6.1691 5.45151 6.1616 5.95267C6.1616 6.44612 5.7566 6.84705 5.2766 6.84705C4.7891 6.84705 4.3916 6.44612 4.3916 5.93725H4.3991Z"
                fill="#FDB93E"
            />
        </g>
        <defs>
            <clipPath id="clip0_320_1324">
                <rect
                    width="15"
                    height="14.3101"
                    fill="white"
                    transform="translate(0.724121)"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'TopCommentSvg'
}
</script>

<style scoped></style>
